<template>
  <b-modal
    id="instant-set-status"
    :title="ticket && ticket.statusAfter ? `${isRewardList ? '' : `#${ticket.number} Instant Win Ticket - `}Set As ${['Contacted', 'Delivered'][ticket.statusAfter - 5]}` : 'Delivery Info'"
    lazy
    centered
    title-class="w-100 d-flex justify-content-center align-self-center"
    header-class="py-2"
    footer-class="py-2"
    body-class="py-0"
    @shown="initForm"
    @hidden="initForm"
    :busy="isLoading">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />

    <p v-if="ticket && ticket.statusAfter">
      ⚠ The {{ isRewardList ? '' : 'ticket' }} status will be changed from <PrizeStatus v-if="isRewardList" :value="ticket.status" /><TicketStatus v-else :value="ticket.status" isReward /> to
      <TicketStatus :value="ticket.statusAfter - (isRewardList ? 3 : 0)" :isReward="isRewardList" />
    </p>

    <b-form @submit.prevent="onSubmit" id="instant-set-status-form">
      <b-form-group label="Ticket Number" label-class="form-label-required">
        <b-form-input :value="ticket ? ticket.number : null" readonly :placeholder="$t('no_data')" />
      </b-form-group>

      <b-form-group label="Order ID" label-class="form-label-required" class="d-inline-block w-50 pr-2">
        <b-form-input :value="ticket ? ticket.order_id : null" readonly :placeholder="$t('no_data')" />
      </b-form-group>
      <b-form-group label="Username" label-class="form-label-required" class="d-inline-block w-50 pl-2">
        <b-form-input :value="ticket && ticket.user ? ticket.user.name : null" readonly :placeholder="$t('no_data')" />
      </b-form-group>

      <template v-if="ticket && (!ticket.statusAfter || ticket.statusAfter == 6)">
        <b-form-group label="Prize" label-class="form-label-required" class="d-inline-block w-50 pr-2">
          <b-form-input :value="product ? product.name : ticket.prize_type.toUpperCase()" readonly />
        </b-form-group>
        <b-form-group :label="product ? 'Cost' : 'Amount'" label-class="form-label-required" class="d-inline-block w-50 pl-2">
          <b-form-input :value="formatWithGBP(product ? product.cost : ticket.prize_amount)" readonly />
        </b-form-group>
      </template>

      <b-form-group label="Comment">
        <b-form-textarea v-model="comment" :disabled="!ticket || !ticket.statusAfter" placeholder="Leave a comment" />
      </b-form-group>

      <template v-if="ticket && !ticket.statusAfter">
        <b-form-group label="Admin ID" class="d-inline-block w-50 pr-2">
          <b-form-input :value="created_by" readonly placeholder="Admin ID" />
        </b-form-group>
        <b-form-group label="Created At" class="d-inline-block w-50 pl-2">
          <b-form-input :value="formatLocalDateTime(ticket.created_at, 1, false, true)" type="datetime-local" readonly placeholder="Created At" />
        </b-form-group>
      </template>

      <template v-if="ticket && (!ticket.statusAfter || ticket.statusAfter == 6)">
        <div>
          Documents
          <b-button v-if="ticket.statusAfter == 6" variant="outline-danger" @click="initImages" class="ml-2"><b-icon-x class="mr-1" />{{ $t('action.reset') }}</b-button>
        </div>
        <b-table :fields="fields" :items="images" :busy="isLoading" head-variant="light" show-empty small hover responsive class="m-0 p-0 mt-3">
          <template #cell(document)="{ value, item }">
            <span>{{ value }}</span>
            <span v-if="product ? item['type'] == 'receipt' : item['type'] == 'poa'" class="text-danger ml-1">*</span>
          </template>
          <template #cell(preview)="{ value, item }">
            <span v-if="item.loading">{{ $t('status.loading') }}</span>
            <b-img-lazy v-else-if="value" :src="value" fluid block rounded style="max-height: 4rem" class="hover-image" />
            <b-badge v-else variant="danger" class="font-weight-bold">⚠ NO IMAGE</b-badge>
          </template>
          <template #cell(file)="{ item }">
            <b-form-file v-model="item['file']" @change="onChange($event, item)" accept="image/*" size="sm" class="h-100" style="width: 172px" />
          </template>
          <template #cell(action)="{ item }">
            <b-button variant="outline-danger" size="xs" @click="clearImage(item)" :disabled="item['uploaded']">
              <b-icon-trash />
            </b-button>
          </template>
          <template #cell(content)="{ item }">
            <b-form-input :value="item['content']" :required="product ? item['type'] == 'receipt' : item['type'] == 'poa'" @input="() => {}" :disabled="!ticket || !ticket.statusAfter" type="url" />
          </template>
        </b-table>
      </template>
    </b-form>
    <template #modal-footer="{ cancel }">
      <b-button variant="outline-secondary" @click="cancel">Close</b-button>
      <b-button v-if="ticket && ticket.statusAfter" variant="outline-primary" type="submit" form="instant-set-status-form">Submit</b-button>
    </template>
  </b-modal>
</template>
<script>
import PrizeStatus from '@/components/PrizeStatus.vue'
import TicketStatus from '@/components/TicketStatus.vue'
import axios from '@/plugins/axios'
import { AwsSecurityUpdate, AWSSecurityWithURL } from '@/store/services/aws'
import { API_LIST } from '@/utils/consts'
import { formatLocalDateTime } from '@/utils/dateTimeUtils'
import { base64Encode } from '@/utils/fileUtils'
import { notifyError, notifySuccess } from '@/utils/index'
import { formatBytes, formatWithGBP } from '@/utils/numberUtils'

export default {
  name: 'InstantWinSetStatus',
  props: { ticket: Object, getList: Function, isRewardList: Boolean },
  components: { TicketStatus, PrizeStatus },
  data() {
    return {
      isLoading: false,
      comment: '',
      images: [
        { type: 'photoID', document: this.$t('winner.photo_id') },
        { type: 'poa', document: 'Proof of Address' },
        { type: 'receipt', document: this.$t('winner.receipt') },
      ],
    }
  },
  computed: {
    fields() {
      const tmp = ['document', 'preview', { key: 'formatted_name', formatter: (v, k, i) => v || i.content?.slice(i.content?.lastIndexOf('/') + 1) }, { key: 'content', label: 'Url' }]
      if (this.ticket?.statusAfter) {
        tmp.splice(2, 0, { key: 'file', label: 'Upload New File' })
        tmp.splice(3, 0, 'action')
        tmp.splice(5, 0, { key: 'size', formatter: (v, k, i) => (i.file ? formatBytes(i.file.size) : ' ') })
      }
      return tmp
    },
    product() {
      return this.ticket.prize_type == 'product' ? this.ticket.product : null
    },
    created_by() {
      return this.ticket?.reward?.profiles?.[this.ticket?.reward?.profiles?.length - 1]?.admin_user_id
    },
  },
  methods: {
    initForm(evt) {
      if (evt.type == 'shown' && (!this.ticket?.statusAfter || this.ticket.statusAfter == 6)) {
        this.initImages()
        const existingComment = this.profile(this.ticket.reward?.profiles, 'delivery_comment')
        this.comment = existingComment ? existingComment.content : ''
      } else {
        this.comment = ''
      }
    },

    onSubmit() {
      this.isLoading = true
      if (this.ticket?.statusAfter == 5) {
        axios
          .post(API_LIST.post.contactWinner, { id: this.ticket.reward.id, contact_comment: this.comment })
          .then((res) => {
            if (res?.status == 200) {
              notifySuccess(res, `Set ${this.isRewardList ? 'prize' : 'instant win ticket'} as contacted successfully!`)
              this.$bvModal.hide('instant-set-status')
              this.getList()
            }
          })
          .catch((err) => notifyError(err, `Failed to set ${this.isRewardList ? 'prize' : 'instant win ticket'} as contacted!`))
          .finally(() => (this.isLoading = false))
      } else if (this.ticket?.statusAfter == 6) {
        return this.uploadImagesAndSubmit()
      }
    },

    profile(profiles, name) {
      return profiles?.find((x) => x['type'] === name)
    },

    async onChange(event, item) {
      const file = event?.target?.files?.[0] || item.file || null
      if (file) {
        this.$set(item, 'loading', true)
        await base64Encode(file)
          .then((res) => {
            this.$set(item, 'preview', res)
            this.$set(item, 'content', res)
            this.$set(item, 'uploaded', false)
            this.$set(item, 'formatted_name', `${this.ticket.order_id}_${this.ticket.reward.id}_${item.type}${file.name.slice(file.name.lastIndexOf('.'))}`)
          })
          .finally(() => this.$set(item, 'loading', false))
      }
    },

    async clearImage(item, existing) {
      this.$set(item, 'id', existing?.id || null)
      this.$set(item, 'file', null)
      this.$set(item, 'content', existing?.content || null)
      this.$set(item, 'uploaded', existing ? true : false)
      this.$set(item, 'formatted_name', existing?.content?.slice(existing?.content?.lastIndexOf('/') + 1) || null)
      if (existing?.content.includes('rkings-security') && !item.loading) {
        this.$set(item, 'loading', true)
        const res = await AWSSecurityWithURL({ name: existing.content.slice(51) })
        this.$set(item, 'preview', (res?.blob ? URL.createObjectURL(res.blob) : null) || existing?.content || null)
        this.$set(item, 'loading', false)
      } else {
        this.$set(item, 'preview', existing?.content || null)
        this.$set(item, 'loading', false)
      }
    },

    initImages() {
      this.images.forEach((item) => {
        const existing = this.profile(this.ticket?.reward?.profiles, item.type)
        this.clearImage(item, existing)
      })
    },

    handleError(error) {
      notifyError(error, this.$t('notify.unknown_err'))
      this.isLoading = false
    },

    uploadImagesAndSubmit() {
      try {
        this.isLoading = true
        let cnt = 0
        const profiles = []
        this.images.forEach(async (item) => {
          let existing = this.profile(this.ticket?.reward?.profiles, item.type)
          if (!item.uploaded && item.file) {
            await AwsSecurityUpdate('rkings-receipts', item.file, item.formatted_name)
              .then((res) => {
                this.$set(item, 'content', res.Location)
                this.$set(item, 'uploaded', true)
                profiles.push({ ...existing, type: item.type, content: res.Location, position: 0 })
              })
              .catch((err) => {
                throw err
              })
          } else if (existing) {
            profiles.push(existing)
          }
          cnt += 1
          if (this.images.length === cnt) {
            return this.updateWinner(profiles)
          }
        })
      } catch (error) {
        this.handleError(error)
      }
    },

    updateWinner(profiles) {
      this.isLoading = true
      const params = { id: this.ticket?.reward?.id, profiles: profiles }
      if (this.comment) {
        const existingComment = this.profile(this.ticket?.reward?.profiles, 'delivery_comment')
        params.profiles.push({ ...existingComment, type: 'delivery_comment', content: this.comment })
      }
      this.ticket?.reward?.profiles?.map((existing) => {
        if (params.profiles.findIndex((item) => existing.id == item.id) == -1) {
          params.profiles.push(existing)
        }
      })
      axios
        .post(API_LIST.post.updateWinnerDetail, params)
        .then((res) => {
          if (res?.status == 200) {
            notifySuccess(res, `Set ${this.isRewardList ? 'prize' : 'instant win ticket'} as delivered successfully!`)
            this.$bvModal.hide('instant-set-status')
            this.getList()
          }
        })
        .catch((err) => notifyError(err, `Failed to set ${this.isRewardList ? 'prize' : 'instant win ticket'} as delivered!`))
        .finally(() => (this.isLoading = false))
    },

    formatWithGBP,
    formatLocalDateTime,
  },
}
</script>
