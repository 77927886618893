<template>
  <card body-classes="p-0 d-flex flex-column" class="table-custom">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <div class="px-4 py-3 w-100 d-flex flex-row justify-content-end">
      <template v-if="isSuperAdmin">
        <b-button variant="basic" v-b-modal.admin-create><b-icon-plus class="mr-1" />{{ $t('admin.add_admin') }}</b-button>
        <AdminCreate :getList="getList" />
      </template>
    </div>
    <b-table :fields="fields" :items="items" :busy="isLoading" show-empty :empty-text="$t('notify.table_no_records')" small hover responsive head-variant="light" class="m-0 p-0" primary-key="id">
      <template #cell(store_id)="{ value }"><store-badge :store_id="value" /></template>
      <template #cell(roles)="{ value, item }">
        <b-form-select
          v-if="roles.length > 0"
          :ref="`role${item.id}`"
          :options="roles"
          :value="value[0].name"
          :disabled="selfPriority >= value[0].priority"
          @change="openConfirm(item, $event)"
          class="text-capitalize"
          style="width: 120px" />
        <template v-else>{{ value[0].name.replace(/-/g, ' ') }}</template>
      </template>
      <template #cell(action)="{ item }">
        <span class="action" v-if="$cookies.get('Email') === item.email" @click="openPass(item)">{{ $t('auth.change_my_password') }}</span>
        <span class="action" v-else-if="isSuperAdmin" @click="openPass(item)">{{ $t('auth.reset_password') }}</span>
        <span v-else>{{ $t('auth.reset_password') }}</span>
      </template>
    </b-table>
    <paginate v-if="items.length > 0" :queries="queries" :fetcher="getList" :total="admins.total" class="card-footer" />
    <AdminPassword :adminDetails.sync="actionTarget" />
    <confirm-modal
      id="confirm-change-role"
      :message="actionTarget ? $t('admin.change_role_notice[0]', [actionTarget.email, actionTarget.msg]) : ''"
      :isLoading="isLoading"
      :onSubmit="updateRole"
      :onHidden="closeConfirm" />
  </card>
</template>
<script>
import AdminCreate from '@/components/Admin/AdminCreate.vue'
import AdminPassword from '@/components/Admin/AdminPassword.vue'
import { formatLocalDateTime } from '@/utils/dateTimeUtils'
import { mapGetters } from 'vuex'
export default {
  components: { AdminCreate, AdminPassword },
  data() {
    return {
      isLoading: false,
      queries: {
        store_id: null,
        page: 1,
        perPage: 10,
        ...this.$route.params?.prev,
      },
      actionTarget: null,
    }
  },
  computed: {
    ...mapGetters({ admins: 'request/adminList', roleList: 'request/roleList', isSuperAdmin: 'isSuperAdmin', selfStore: 'store' }),
    fields() {
      return [
        { key: 'id', sortable: true },
        { key: 'name', label: this.$t('general.name'), sortable: true },
        { key: 'email', label: this.$t('general.email'), sortable: true },
        { key: 'store_id', label: this.$tc('general.store', 1) },
        { key: 'roles', label: this.$tc('admin.role', 1), sortable: true, tdClass: 'text-capitalize' },
        { key: 'action', label: this.$tc('general.action', 1) },
        { key: 'created_at', label: this.$t('general.created_at'), formatter: (v) => formatLocalDateTime(v), sortable: true },
      ]
    },
    items() {
      return this.admins?.data ?? []
    },
    selfPriority() {
      return parseInt(this.$cookies.get('RolePriority'))
    },
    roles() {
      return this.roleList
        ? this.roleList.map((el) => ({ value: el.name, text: el.name.replace(/-/g, ' '), disabled: el.priority < this.selfPriority, priority: el.priority })).sort((a, b) => b.priority - a.priority)
        : []
    },
  },
  methods: {
    getList(_p) {
      this.isLoading = true
      const p = { ...this.queries, ..._p }
      this.$store
        .dispatch('request/getAdmins', p)
        .then(() => (this.isLoading = false))
        .catch(() => (this.isLoading = false))
    },
    onSearch() {
      this.queries.page = 1
      this.getList()
    },
    onReset() {
      this.queries.store_id = this.selfStore
      this.onSearch()
    },
    openConfirm(row, e) {
      if (e !== row.tag) {
        this.actionTarget = row
        this.actionTarget.msg = e
        this.$bvModal.show('confirm-change-role')
      }
    },
    closeConfirm() {
      this.$refs[`role${this.actionTarget.id}`].value = this.actionTarget.roles[0].name
      this.$refs[`role${this.actionTarget.id}`].localValue = this.actionTarget.roles[0].name
      this.actionTarget = null
    },
    updateRole() {
      this.isLoading = true
      const role_id = this.roleList.find((x) => x.name === this.actionTarget?.msg)?.id
      this.$store
        .dispatch('request/updateRole', { admin_id: this.actionTarget.id, role_id: role_id, errorMsg: 'Change role failed!' })
        .then((res) => {
          this.isLoading = false
          if (res.status === 200) {
            this.$notify({ group: 'root', type: 'success', text: res.data.data[0] ?? this.$t('admin.change_role_notice[1]') })
            this.getList()
          }
        })
        .catch(() => (this.isLoading = false))
    },
    openPass(row) {
      this.actionTarget = row
      this.$bvModal.show('admin-change-pass')
    },
  },
  created() {
    this.queries.store_id = this.selfStore
    this.getList()
    if (!this.roleList) {
      this.isLoading = true
      this.$store
        .dispatch('request/getRoleList')
        .then(() => (this.isLoading = false))
        .catch(() => (this.isLoading = false))
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$set(from.params, 'preservedParams', this.queries)
    return next()
  },
}
</script>
<style scoped>
::v-deep .modal-dialog {
  width: fit-content;
  min-width: 30%;
  max-width: 50%;
}
@media screen and (max-width: 1024px) {
  ::v-deep .modal-dialog {
    min-width: 75%;
    max-width: 90%;
  }
}
</style>
