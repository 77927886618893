<template>
  <div style="display: contents">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />

    <div class="px-4 pt-3 pb-2 d-flex flex-row flex-wrap align-items-start">
      <b-form-input v-if="isSearchable" v-model="localParams.id" type="search" :placeholder="$t('transaction.transaction_id')" style="width: 146px" class="mr-2 mb-2" @keyup.enter="onSearch" />
      <b-form-select v-model="localParams.status" :disabled="!isSearchable" class="mr-2 mb-2" style="width: 128px">
        <b-form-select-option v-for="(option, index) in $t('transaction.credit_status')" :key="index" :value="index - 2">{{ option }}</b-form-select-option>
        <b-form-select-option :value="null" class="text-placeholder">{{ $t('status.all_status') }}</b-form-select-option>
      </b-form-select>
      <b-form-select v-model="localParams.sourceable_type" :disabled="!isSearchable" class="mr-2 mb-2" style="width: 128px">
        <b-form-select-option disabled hidden :value="null">{{ $t('general.source') }}</b-form-select-option>
        <b-form-select-option :value="'App\\Models\\CashbackConfig'">{{ $t('cashback.title') }}</b-form-select-option>
        <b-form-select-option :value="'App\\Models\\Competitions\\CompetitionReward'">{{ $t('instant_win.title') }}</b-form-select-option>
        <b-form-select-option :value="'App\\Models\\Users\\UserBalancePayment'">{{ $t('topup.title') }}</b-form-select-option>
        <b-form-select-option :value="'App\\Models\\Users\\UserReward'">{{ $t('competition.convert_prize') }}</b-form-select-option>
        <b-form-select-option :value="'manual'">{{ $t('competition.draw_method[1]') }}</b-form-select-option>
      </b-form-select>
      <b-form-input v-if="isSearchable" v-model="localParams.rate" min="0" type="search" :placeholder="$t('cashback.rate') + ' (%)'" style="width: 128px" class="mr-2 mb-2" @keyup.enter="onSearch" />
      <b-form-input v-if="isSearchable" v-model="localParams.name" type="search" :placeholder="$t('general.username')" style="width: 146px" class="mr-2 mb-2" @keyup.enter="onSearch" />
      <div class="d-flex position-relative mr-2 mb-2" v-b-tooltip.hover.top="$t('notify.date_range_notice', { name: $t('general.created_at') })">
        <span v-if="!selectingDate && (!dateRange || (!dateRange['startDate'] && !dateRange['endDate']))" class="dt-picker-placeholder">
          {{ $t('general.created_at') }}
        </span>
        <date-range-picker
          v-if="isSearchable"
          v-model="dateRange"
          :locale-data="{ format: 'yyyy-mm-dd' }"
          :auto-apply="true"
          :ranges="ranges"
          :timePicker24Hour="true"
          :show-dropdowns="true"
          :max-date="formatLocalDateTime(null, 'YYYY-MM-DD', true, true)"
          @toggle="toggleSelection"
          @start-selection="toggleSelection()"
          @finish-selection="toggleSelection()"
          class="m-0"
          style="width: max-content; min-width: 220px" />
      </div>
      <button-group v-if="isSearchable" :onSearch="onSearch" :onReset="onReset" :showExport="showExport" :onExport="onExport" />
      <template v-if="hasSlot('create')"><slot name="create"></slot></template>
    </div>
    <!-- Table -->
    <b-table
      :fields="fields"
      :items="items"
      :busy="isLoading"
      show-empty
      :empty-text="$t('notify.table_no_records')"
      small
      hover
      responsive
      head-variant="light"
      sticky-header="100%"
      class="m-0 p-0"
      ref="cbTransTable"
      no-local-sorting
      @sort-changed="sortingChanged">
      <template #cell(id)="{ value, item }">
        <template v-if="hasSlot('id')">
          <slot name="id" :val="value" :row="item"></slot>
        </template>
        <template v-else>{{ value }}</template>
      </template>
      <template #cell(comment)="{ value }">
        <span v-b-tooltip.hover.right="value">{{ value }}</span>
      </template>
      <template #cell(admin)="{ value }">
        <template v-if="value">{{ value }}</template>
        <b-badge v-else variant="danger">{{ $t('admin.no_admin') }}</b-badge>
      </template>
      <template #cell(status)="{ value }">
        <CreditTransStatus :value="value" />
      </template>
      <template #cell(action)="{ item }">
        <template v-if="hasSlot('action')">
          <slot name="action" :row="item" :user="item.user"></slot>
        </template>
      </template>
      <template #bottom-row v-if="items.length > 0">
        <td colspan="1" class="font-weight-bold">{{ $t('general.total') }}</td>
        <td colspan="4"></td>
        <td colspan="1" class="font-weight-bold text-right">{{ trans.page_amount }} / {{ trans.total_amount }}</td>
        <td colspan="6"></td>
      </template>
    </b-table>
    <paginate v-if="items.length > 0" :queries="localParams" :fetcher="getList" :total="trans.total" class="card-footer" />
  </div>
</template>
<script>
import CreditTransStatus from '@/components/CreditTransStatus.vue'
import utilsMixin from '@/mixins/utils-mixin'
import { API_LIST } from '@/utils/consts'
import { formatDayEndUtc, formatDayStartUtc, formatLocalDateTime, formatUtcDateTime, setupDateRanges } from '@/utils/dateTimeUtils'
import { exportAsExcel } from '@/utils/fileUtils'
import { mapGetters } from 'vuex'

export default {
  name: 'CashbackTrans',
  components: { CreditTransStatus },
  mixins: [
    utilsMixin, // hasSlot
  ],
  props: {
    fields: Array,
    localParams: Object,
    getList: Function,
    isLoading: Boolean,
    isSearchable: Boolean,
    showExport: Boolean,
  },
  data() {
    return {
      ranges: setupDateRanges(),
      selectingDate: false,
      colsToExport: ['id', 'status', 'comment', 'source_amount', 'cashback_rate', 'cashback_amount', 'user_name', 'created_at', 'updated_at'],
    }
  },
  computed: {
    ...mapGetters({ trans: 'request/cashbackTrans' }),
    items() {
      return this.trans?.data ?? []
    },
    dateRange: {
      get() {
        return {
          startDate: formatLocalDateTime(this.localParams.created_start, 1, false, true),
          endDate: formatLocalDateTime(this.localParams.created_end, 1, false, true),
        }
      },
      set(newVal) {
        this.localParams['created_start'] = formatDayStartUtc(newVal.startDate, 0, false, true)
        this.localParams['created_end'] = formatDayEndUtc(newVal.endDate, 0, false, true)
        return newVal
      },
    },
  },
  methods: {
    onSearch() {
      this.localParams.page = 1
      this.getList()
    },
    onReset() {
      this.localParams.id = null
      this.localParams.status = null
      this.localParams.sourceable_type = 'App\\Models\\CashbackConfig'
      this.localParams.rate = null
      this.localParams.name = null
      this.localParams.created_start = null
      this.localParams.created_end = null
      this.dateRange['startDate'] = null
      this.dateRange['endDate'] = null
      this.localParams['orderBy'] = null
      this.localParams['direction'] = null
      this.$refs.cbTransTable['sortBy'] = ''
      this.$refs.cbTransTable['localSortBy'] = ''
      this.onSearch()
    },
    sortingChanged(ctx) {
      const filter = ['cashback_rate', 'cashback_amount']
      this.localParams['orderBy'] = !ctx.sortBy ? undefined : filter.indexOf(ctx.sortBy) > -1 ? ['sourceable_id', 'points'][filter.indexOf(ctx.sortBy)] : ctx.sortBy
      this.localParams['direction'] = !ctx.sortBy ? undefined : ctx.sortDesc ? 'desc' : 'asc'
      this.getList()
    },
    toggleSelection(show, event) {
      if (typeof show == 'undefined') {
        this.selectingDate = !this.selectingDate
      } else if (show == false) {
        this.selectingDate = false
      }
    },
    preProcess(data) {
      for (let i in data) {
        const row = data[i]
        data[i].status = this.$t(`transaction.credit_status[${row.status + 2}]`)
        data[i].cashback_rate = row.cashback_rate && row.cashback_rate > 0 ? row.cashback_rate * 100 : 0
        data[i].user_name = row.user?.name || ''
        data[i].created_at = formatLocalDateTime(row.created_at)
        data[i].updated_at = formatLocalDateTime(row.updated_at)
        for (const key in data[i]) {
          if (!this.colsToExport.includes(key)) {
            delete data[i][key]
          }
        }
      }
    },
    onExport() {
      return exportAsExcel(
        'CreditHistory_' + formatUtcDateTime(undefined, 2, true),
        API_LIST.get.cashbackTrans,
        { ...this.localParams, page: 1, perPage: 1000 },
        this.trans.total,
        this.preProcess,
        this.colsToExport,
        [
          [
            this.$t('general.id'),
            this.$t('general.status'),
            this.$tc('general.comment', 1),
            this.$t('cashback.total_payment'),
            this.$t('cashback.rate') + ' (%)',
            this.$tc('general.amount', 1),
            this.$t('general.username'),
            this.$t('general.created_at'),
            this.$t('general.updated_at'),
          ],
        ],
        (bool) => this.$emit('update:isLoading', bool)
      )
    },
    formatLocalDateTime,
  },
  mounted() {
    this.getList()
  },
}
</script>
