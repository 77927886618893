<template>
  <div v-if="competition" style="display: contents">
    <b-row cols="1">
      <b-col>
        <div class="d-flex flex-row flex-nowrap align-items-center font-weight-bold">
          <span v-if="!competition.title">{{ $t('no_data') }}</span>
          <template v-else-if="!isDetailsRoute && hasSlot('title')">
            <slot name="title" :competition="competition"></slot>
          </template>
          <span v-else>{{ competition.title }}</span>
          <icon-translate v-if="competition.titleLang" :onClick="() => pushCompEdit(competition)" />
          <CompetitionStatus v-if="!showStores && !hasSlot('title')" :value="competition['status']" class="ml-2" />
          <a v-if="showPreviewBtn" :href="href" target="_blank">
            <b-button variant="success" class="ml-2" v-b-tooltip.hover.right="href"> <b-icon-eye class="mr-1" />{{ $t('action.preview') }} </b-button>
          </a>
        </div>
      </b-col>
    </b-row>
    <b-row cols="1" cols-xl="2">
      <b-col class="pr-xl-4">
        <div :class="pairClass">
          <span :class="labelClass">{{ $t('general.id') }}</span>
          <span :class="valClass">{{ competition.id ? competition.id : $t('no_data') }}</span>
        </div>
        <div :class="pairClass" v-if="!showStores">
          <span :class="labelClass">{{ $tc('general.store', 1) }}</span>
          <div :class="`d-inline-block ${valClass}`">
            <template v-for="(val, index) in [competition.store_id, secondStore]">
              <store-badge v-if="val" :key="'status-store-' + index" :store_id="val" :class="` ${index > 0 ? 'ml-1' : ''}`" />
            </template>
          </div>
        </div>
        <div :class="pairClass" v-if="products">
          <span :class="labelClass">{{ $tc('general.product', products.length) }}</span>
          <div class="ml-3 font-weight-light col-8 text-md d-inline-block">
            <template v-for="(item, i) in products">
              <span v-if="!item.nameLang" class="action d-block" :key="'comp-product-' + i" @click="viewProductDetails(item)">
                {{ noTransFormatter(item.name, 'name', item) }}
              </span>
              <div v-else :key="'comp-product-lang-' + i" class="d-flex flex-row flex-nowrap align-items-center">
                <span class="action" @click="viewProductDetails(item)">
                  {{ noTransFormatter(item.name, 'name', item) }}
                </span>
                <icon-translate :onClick="() => pushProductEdit(item)" />
              </div>
            </template>
          </div>
        </div>
        <div :class="pairClass">
          <span :class="labelClass">{{ $t('competition.short_name') }}</span>
          <span v-if="!competition.short_name" class="ml-3 font-weight-light">{{ $t('no_data') }}</span>
          <span v-else-if="isDetailsRoute" class="ml-3 font-weight-light col-8">{{ competition.short_name }}</span>
          <span v-else class="ml-3 font-weight-light text-truncate" v-b-tooltip.hover.right="competition.short_name">{{ competition.short_name }}</span>
        </div>
        <template v-if="isDetailsRoute">
          <div
            :class="pairClass"
            v-for="({ value, label }, index) in [
              { key: 'cost', label: $t('competition.prize_cost'), value: competition.cost },
              { key: 'cash_alt', label: $t('competition.cash_alt'), value: cash_alt },
            ]"
            :key="'comp-details-with-currency-' + index">
            <span :class="labelClass">{{ label }}</span>
            <span :class="valClass">{{ value }}</span>
          </div>
          <template
            v-for="({ label, show, val }, index) in [
              {
                show: true,
                key: 'draw_method',
                label: $t('competition.draw_method[0]'),
                val: $t(`competition.generate_method[${['shuffle', 'sequential'].indexOf(competition.generate_method) + 1}]`),
              },
              {
                show: true,
                key: 'generate_method',
                label: $t('competition.generate_method[0]'),
                val: $t(`competition.draw_method[${['manual', 'auto', 'consolation'].indexOf(competition.draw_method) + 1}]`),
              },
              { show: true, key: 'stock_amount', label: $t('competition.total_ticket_amt'), val: competition.stock_amount ? competition.stock_amount : $t('no_data') },
              { show: !showStores, key: 'default_ticket_amount', label: $t('competition.default_ticket_amt'), val: stores[0]['default_ticket_amount'] },
              { show: true, key: 'user_limit_amount', label: $t('competition.user_limit_amt'), val: competition.user_limit_amount ? competition.user_limit_amount : $t('no_data') },
            ]">
            <div v-if="show" :key="'comp-details-' + index" :class="pairClass">
              <span :class="labelClass">{{ label }}</span>
              <span :class="valClass">{{ val }}</span>
            </div>
          </template>
        </template>
        <template
          v-for="({ key, label, show, val }, index) in [
            { key: 'start_time', label: $t('competition.opening_date'), show: !showStores, val: stores[0]['start_time'] },
            { key: 'end_time', label: $t('competition.closing_date'), show: !showStores, val: stores[0]['end_time'] },
            { key: 'draw_date', label: $t('competition.draw_date'), show: true },
            { key: 'closed_at', label: $t('competition.closed_at'), show: !isBannerRoute },
            { key: 'created_at', label: $t('general.created_at'), show: !showStores, val: stores[0]['created_at'] },
            { key: 'updated_at', label: $t('general.updated_at'), show: !showStores, val: stores[0]['updated_at'] },
          ]">
          <div v-if="show" :key="'comp-details-time-' + index" :class="pairClass">
            <span :class="labelClass">{{ label }}</span>
            <span :class="valClass">{{ formatLocalDateTime(val ? val : competition[key]) }}</span>
          </div>
        </template>
        <div :class="pairClass" v-if="isDetailsRoute">
          <span :class="labelClass">{{ $t('general.updated_by') }}</span>
          <span :class="valClass">{{ competition['admin'] ? competition['admin']['name'] : $t('no_data') }}</span>
        </div>
      </b-col>
      <b-col class="pl-xl-4">
        <div class="mt-3 text-center text-xl-left" v-if="top_image">
          <b-img-lazy :src="top_image" :alt="$t('general.top_image')" class="top-image" rounded />
        </div>
        <div class="mt-3" v-if="tags && tags.length > 0 && !isBannerRoute">
          <span class="font-weight-bold">{{ $tc('competition.tag', 1) }}</span>
          <b-badge v-for="(tag, index) in tags" :key="'comp-tag-' + index" variant="secondary" class="ml-2">
            {{ tag.value.replace('_', ' ') }}
          </b-badge>
        </div>
        <div class="mt-3" v-if="competition.images && !isBannerRoute">
          <p class="font-weight-bold">{{ $tc('general.image', competition.images.length) }}</p>
          <b-table
            show-empty
            :empty-text="$t('notify.table_no_records')"
            small
            hover
            striped
            responsive
            sticky-header="320px"
            head-variant="light"
            class="mx-0 mt-3 mb-0 p-0 flex-grow-1"
            tbody-class="h-100"
            :fields="[
              { key: 'id', label: $t('general.id') },
              { key: 'status', label: $t('general.status') },
              { key: 'preview', label: $t('general.preview') },
              { key: 'position', label: $t('general.position') },
            ]"
            :items="images">
            <template #cell(status)="{ value }">
              <bool-badge :value="value" :text="value == 1 ? $t('status.shown') : $t('status.hidden')" />
            </template>
            <template #cell(preview)="{ item }">
              <a :href="`${item.value}`" target="_blank">
                <b-img-lazy :src="item.value" fluid block rounded style="max-height: 4rem" class="hover-image" />
              </a>
            </template>
            <template #bottom-row v-if="competition.images.length >= 1">
              <td colspan="3" class="font-weight-bold">
                {{ $t('general.total') }}
              </td>
              <td colspan="1" class="font-weight-bold">
                {{ competition.images.length + ' ' + $tc('general.image', competition.images.length) }}
              </td>
            </template>
          </b-table>
        </div>
      </b-col>
    </b-row>
    <b-row cols="1" cols-xl="2" v-if="showTables">
      <b-col class="pr-xl-4">
        <p class="font-weight-bold mt-3">{{ $t('competition.question') }}</p>
        <p class="font-weight-light">{{ competition['quiz']['question'] }}</p>
        <QuizAnswer v-for="(item, i) in competition['quiz']['answers']" :key="'comp-answers-' + i" :value="item.is_correct" class="w-75 mb-3 d-block" :text="item.answer" />
      </b-col>
      <b-col class="pl-xl-4">
        <div class="d-flex flex-row flex-nowrap align-items-center font-weight-bold mt-3">
          {{ $t('general.description') }}
          <icon-translate v-if="competition.descrLang" :onClick="() => pushCompEdit(competition)" />
        </div>
        <b-form-textarea id="detail-description" v-model="description" disabled size="lg" rows="7" no-resize style="font-size: 0.85rem" />
      </b-col>
    </b-row>

    <b-row v-if="showStores">
      <b-col>
        <p class="font-weight-bold mt-3">{{ $tc('general.store', 2) }}</p>
        <b-table-lite
          id="detail-stores"
          small
          hover
          responsive
          head-variant="light"
          :fields="[
            { key: 'store_id', label: $tc('general.store', 1) },
            { key: 'status', label: $t('general.status') },
            { key: 'default_ticket_amount', label: $t('competition.default_ticket_amount') },
            { key: 'start_time', label: $t('competition.opening_date'), formatter: (v) => formatLocalDateTime(v) },
            { key: 'end_time', label: $t('competition.closing_date'), formatter: (v) => formatLocalDateTime(v) },
            { key: 'created_at', label: $t('general.created_at'), formatter: (v) => formatLocalDateTime(v) },
            { key: 'updated_at', label: $t('general.updated_at'), formatter: (v) => formatLocalDateTime(v) },
          ]"
          :items="stores">
          <template #cell(store_id)="{ value }"><store-badge :store_id="value" /></template>
          <template #cell(status)="{ value }"><CompetitionStatus :value="value" /></template>
        </b-table-lite>
      </b-col>
    </b-row>

    <b-row cols="1" cols-xl="2" v-if="showTables">
      <b-col class="pr-xl-4">
        <p class="font-weight-bold mt-3">{{ $tc('general.catalog', 2) }}</p>
        <b-table
          id="detail-catalogs"
          show-empty
          :empty-text="$t('notify.table_no_records')"
          small
          hover
          striped
          responsive
          sticky-header="12rem"
          head-variant="light"
          class="m-0 p-0 flex-grow-1"
          tbody-class="h-100"
          :fields="[
            { key: 'store_id', label: $tc('general.store', 1) },
            { key: 'name', label: $t('catalog.catalog_name') },
            { key: 'display_all', label: $t('catalog.display_all') },
            { key: 'position', label: $t('general.position') },
            { key: 'created_at', label: $t('general.created_at'), formatter: (v) => formatLocalDateTime(v) },
            { key: 'updated_at', label: $t('general.updated_at'), formatter: (v) => formatLocalDateTime(v) },
          ]"
          :items="catalogs">
          <template #cell(store_id)="{ value }"><store-badge :store_id="value" /></template>
          <template #cell(display_all)="{ value }">
            <bool-badge :value="value" :text="value == 1 ? $t('yes') : $t('no')" />
          </template>
        </b-table>
      </b-col>
      <b-col class="pl-xl-4">
        <p class="font-weight-bold mt-3">{{ $tc('competition.price', 2) }}</p>
        <b-table-lite
          id="detail-prices"
          small
          hover
          responsive
          head-variant="light"
          :fields="[
            { key: 'store_id', label: $tc('general.store', 1) },
            { key: 'type', label: $t('general.type') },
            { key: 'currency_code', label: $t('general.currency') },
            { key: 'amount', label: $tc('general.amount', 1) },
            { key: 'status', label: $t('general.status') },
            { key: 'created_at', label: $t('general.created_at'), formatter: (v) => formatLocalDateTime(v) },
            { key: 'updated_at', label: $t('general.updated_at'), formatter: (v) => formatLocalDateTime(v) },
          ]"
          :items="prices">
          <template #cell(store_id)="{ value }"><store-badge :store_id="value" /></template>
          <template #cell(type)="{ value }">
            <b-badge pill :variant="['info', 'primary', 'warning', 'danger'][value]">{{ $t('competition.price_type')[value] }}</b-badge>
          </template>
          <template #cell(status)="{ value }">
            <b-badge pill :variant="['danger', 'warning', 'success'][value + 1]">{{ $t('competition.price_status')[value + 1] }}</b-badge>
          </template>
        </b-table-lite>
      </b-col>
    </b-row>
    <InstantWinInfo v-if="instantWins.length > 0" :competition="competition" :instantWins="instantWins" :viewProductDetails="viewProductDetails" />

    <b-modal
      lazy
      centered
      id="product-details"
      :title="$t('product.product_details', { id: actionTarget ? ' #' + actionTarget.id : '' })"
      title-class="w-100 d-flex justify-content-center align-self-center"
      header-class="py-2"
      body-class="pt-0"
      dialog-class="product-details-modal"
      hide-footer>
      <ProductDetails v-if="actionTarget" :product="actionTarget" />
    </b-modal>
  </div>
</template>
<script>
import ProductDetails from '@/components/Product/ProductDetails.vue'
import routerMixin from '@/mixins/router-mixin'
import utilsMixin from '@/mixins/utils-mixin'
import { formatLocalDateTime } from '@/utils/dateTimeUtils'
import { noTransFormatter } from '@/utils/index'
import { formatWithCurrency } from '@/utils/numberUtils'
import { mapGetters } from 'vuex'
import QuizAnswer from '../QuizAnswer.vue'
import CompetitionStatus from './CompetitionStatus.vue'
import InstantWinInfo from './InstantWinInfo.vue'

export default {
  name: 'CompetitionDetails',
  mixins: [utilsMixin, routerMixin],
  components: { ProductDetails, CompetitionStatus, InstantWinInfo, QuizAnswer },
  props: { competition: Object, showTables: Boolean },
  data() {
    return {
      actionTarget: null,
      secondStore: null,
      instantWins: this.competition.rewards?.filter((v) => v.type == 'consolation') || [],
    }
  },
  computed: {
    ...mapGetters({ allowMultiStores: 'allowMultiStores', selfStore: 'store' }),
    showStores() {
      return this.competition.stores && this.competition.stores.length > 0 && this.allowMultiStores
    },
    stores() {
      let tmp = this.competition?.stores?.map((store) => store) ?? []
      tmp.splice(0, 0, {
        store_id: this.competition?.store_id,
        status: this.competition?.status,
        start_time: this.competition?.start_time,
        end_time: this.competition?.end_time,
        default_ticket_amount: this.competition?.default_ticket_amount,
        created_at: this.competition?.created_at,
        updated_at: this.competition?.updated_at,
      })
      if (!this.allowMultiStores) {
        tmp.sort((a, b) => Math.abs(a.store_id - this.selfStore) - Math.abs(b.store_id - this.selfStore))
      }
      return tmp
    },
    catalogs() {
      let tmp = this.competition?.catalogs ?? []
      if (!this.allowMultiStores) {
        tmp = tmp.filter((x) => x.store_id == this.selfStore)
      }
      return tmp
    },
    prices() {
      let tmp = this.competition?.prices ?? []
      if (!this.allowMultiStores) {
        tmp = tmp.filter((x) => x.store_id == this.selfStore)
      }
      return tmp
    },
    description() {
      return this.competition['description']?.replace(/<[^>]+>/g, '')
    },
    tags() {
      return this.competition['attributes']?.filter((x) => x.code === 'tag')
    },
    images() {
      const tmp = this.competition?.images ?? []
      if (tmp.length > 0) tmp.sort((a, b) => a.position - b.position)
      return tmp
    },
    top_image() {
      return this.competition?.['image'] ?? this.competition?.['images']?.[0]?.value ?? this.competition?.['rewards']?.[0]?.product?.attributes?.filter((x) => x.code == 'image')[0]?.value
    },
    products() {
      return this.competition?.rewards?.reduce?.((arr, item) => {
        if (item.type != 'winner') {
          return arr
        } else if (!item.product) {
          return arr.concat({ id: item.product_id, name: '#' + item.product_id })
        } else {
          item.product['name'] = noTransFormatter(item.product.name, 'name', item.product)
          item.product['description'] = noTransFormatter(item.product.description, 'descr', item.product)
          return arr.concat(item.product)
        }
      }, [])
    },
    cash_alt() {
      let tmp = ''
      this.competition['cash_alt']?.forEach((cash, i) => {
        if (this.allowMultiStores || ['GBP', 'MXN'].indexOf(cash.currency) == this.selfStore - 1) {
          tmp += cash && cash['amount'] !== null ? (this.allowMultiStores && i > 0 ? ' or ' : '') + formatWithCurrency(cash.amount, cash.currency) : ''
        }
      })
      return tmp.length > 1 ? tmp : this.$t('no_data')
    },
    isDetailsRoute() {
      return this.$route.path.indexOf('/competitions/competition-list/details') > -1
    },
    isBannerRoute() {
      return this.$route.path.indexOf('/banners') > -1
    },
    pairClass() {
      return `mt-3 ${this.isDetailsRoute ? 'row' : ''}`
    },
    labelClass() {
      return `font-weight-bold text-sm align-top ${this.isDetailsRoute ? 'col-3' : ''}`
    },
    valClass() {
      return `ml-3 font-weight-light ${this.isDetailsRoute ? 'col-8' : ''}`
    },
    showPreviewBtn() {
      return this.isDetailsRoute && this.selfStore == 1 && this.stores.findIndex((x) => x.store_id == 1) > -1 && this.competition.url
    },
    href() {
      return this.competition.url + (this.competition.status == 0 && this.competition.url.indexOf('token=') > -1 ? '&ticketsLeft=' + this.competition.stock_amount : '')
    },
  },
  methods: {
    viewProductDetails(obj) {
      this.actionTarget = obj
      this.$bvModal.show('product-details')
    },
    formatLocalDateTime,
    noTransFormatter,
  },
  mounted() {
    if (this.competition?.stores?.length > 0) {
      this.secondStore = this.competition?.stores[0].store_id
    }
  },
}
</script>
<style scoped>
.product-details-modal {
  width: max-content !important;
  min-width: 56rem !important;
  max-width: 90vw !important;
}

::v-deep .modal-dialog {
  width: fit-content;
  min-width: 50%;
  max-width: 75%;
}

.top-image {
  object-fit: cover;
  width: auto;
  max-width: 100%;
  max-height: 12rem;
}

@media screen and (max-width: 1200px) {
  .top-image {
    width: 75%;
    height: auto;
    max-height: unset;
  }
}

@media screen and (max-width: 768px) {
  .top-image {
    width: 100%;
  }
}
</style>
