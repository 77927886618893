<template>
  <b-badge :variant="rwdStatusColor[idx]">{{ RWD_STATUS_LIST[idx].replace(/_/g, ' ') }}</b-badge>
</template>
<script>
import { RWD_STATUS_LIST } from '@/utils/consts';

export default {
  name: 'PrizeStatus',
  props: {
    value: String || Number,
    next: Boolean,
  },
  data() {
    return {
      rwdStatusColor: ['light', 'success', 'info', 'primary', 'dark'],
      RWD_STATUS_LIST,
    }
  },
  computed: {
    idx() {
      const tmp = typeof this.value !== 'number' ? RWD_STATUS_LIST.indexOf(this.value) : this.value
      return this.next ? tmp + 1 : tmp
    },
  },
}
</script>
