<template>
  <card body-classes="p-0 d-flex flex-column" class="table-custom">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <ReportSearchInput :queryParams="queryParams" :showDatePicker="dateOption.text" :onExport="onExport" :onSearch="getList" :onReset="onReset">
      <template #other-search>
        <!-- <b-form-input v-model="queryParams['competition_id']" type="search" :placeholder="$t('competition.comp_id')" @keyup.enter="getList" class="mb-2 mr-2" style="width: 140px" /> -->
        <!-- <b-form-input v-model="queryParams['competition_title']" type="search" :placeholder="$t('competition.comp_title')" @keyup.enter="getList" class="mb-2 mr-2" style="width: 160px" /> -->
        <b-form-select v-model="queryParams['date_field']" class="mb-2 mr-2" style="width: 130px" :options="dateOptions" />
      </template>
    </ReportSearchInput>
    <b-table :fields="fields" :items="items" show-empty striped hover responsive sticky-header="100%" head-variant="light" class="m-0 p-0">
      <template #head()="{ label, field }">{{ label }}<b-icon-question-circle-fill v-if="field.tooltip" class="ml-1" v-b-tooltip.hover.topright="field.tooltip" /></template>
      <template #cell(competition_id)="{ value, item }">
        <router-link :to="{ name: 'Competition Details', query: { id: value }, params: { competition: item } }">{{ value }} </router-link>
      </template>
      <template #bottom-row v-if="items.length > 0">
        <td colspan="1" class="table-b-table-default b-table-sticky-column">
          {{ $t('general.total') }}
        </td>
        <td colspan="3"></td>
        <td v-for="keyName in keysToSum" :key="keyName" class="text-right">{{ formatWithCurrency(bottomRow[keyName]) }}</td>
        <td colspan="2"></td>
      </template>
    </b-table>
  </card>
</template>
<script>
import ReportSearchInput from '@/components/Reports/ReportSearchInput.vue'
import { API_LIST } from '@/utils/consts'
import { exportAsExcel } from '@/utils/fileUtils'
import { notNullStr } from '@/utils/index'
import { sumTotal } from '@/utils/numberUtils'
import axios from '../../plugins/axios'

export default {
  components: { ReportSearchInput },
  data() {
    const params = {
      instant_only: true,
      // competition_id: null,
      // competition_title: null,
      date_field: 'start',
      start_date: null,
      end_date: null,
      timezone: this.$store.getters['settings/timezone'],
    }
    return {
      isLoading: false,
      queryParams: params,
      initialParams: { ...params },
      localParams: {},
      dateOptions: [
        { value: 'start', text: 'Opening Date' },
        { value: 'close', text: 'Closing Date' },
      ],
      keysToSum: ['total_sales', 'coupon_amount', 'net_sales', 'draw_cost', 'instant_product_cost', 'instant_cash_cost', 'instant_credit_cost', 'budget_gross_profit', 'profits'],
    }
  },
  computed: {
    dateOption() {
      return this.dateOptions.find((x) => x.value == this.queryParams.date_field)
    },
    items: {
      get() {
        return this.$store.getters['request/competitionReport'] ?? []
      },
      set: (v) => v,
    },
    fields() {
      const tmp = [
        { key: 'competition_id', label: this.$t('competition.comp_id'), stickyColumn: true, tooltip: 'Click for competition details', class: 'pr-0' },
        { key: 'competition_title', label: 'Competition Title', stickyColumn: true, tdClass: 'text-wrap', tdAttr: { style: 'min-width: 320px' } },
        { key: 'live_date', label: 'Opening Date', sortable: true },
        { key: 'end_date', label: 'Closing Date', sortable: true },
        { key: 'total_sales', label: this.$t('report.competition.total_sales'), tooltip: this.$t('report.competition.total_sales_notice'), thStyle: 'min-width: 150px' },
        { key: 'coupon_amount', label: this.$t('report.competition.coupon_amount') },
        { key: 'net_sales', label: this.$t('report.competition.net_sales'), tooltip: this.$t('report.competition.net_sales_notice'), thStyle: 'min-width: 100px' },
        { key: 'draw_cost', label: 'Live Draw Cost', thStyle: 'min-width: 120px' },
        { key: 'instant_product_cost', label: 'Instant Product Won', thStyle: 'min-width: 140px' },
        { key: 'instant_cash_cost', label: 'Instant Cash Won', thStyle: 'min-width: 120px' },
        { key: 'instant_credit_cost', label: 'Instant Credit Won', thStyle: 'min-width: 140px' },
        { key: 'budget_gross_profit', label: 'Expected Profit' },
        { key: 'profits', label: 'Live Profit' },
        {
          key: 'budget_margin',
          label: 'Expected Profit Margin',
          tooltip: 'EXPECTED PROFIT MARGIN = (EXPECTED SALES - EXPECTED COST) / EXPECTED SALES * 100%',
          thStyle: 'min-width: 180px',
        },
        {
          key: 'actual_margin',
          label: 'Live Profit Margin',
          tooltip: 'LIVE PROFIT MARGIN = (NET SALES - LIVE COST) / NET SALES * 100%',
          thStyle: 'min-width: 120px',
        },
      ]
      for (let i = 4; i < tmp.length; i++) {
        if (i < 13) {
          tmp[i]['formatter'] = (v) => this.formatWithCurrency(v)
        } else if (i < 15) {
          tmp[i]['formatter'] = (v) => (v ? `${v}%` : '-')
        }
        tmp[i]['thClass'] = 'text-center text-wrap'
        tmp[i]['tdClass'] = 'text-right'
        tmp[i]['sortable'] = true
      }
      return tmp
    },
    bottomRow() {
      return this.keysToSum.reduce(
        (acc, keyName) => {
          acc[keyName] = sumTotal(this.items, keyName)
          return acc
        },
        { competition_id: 'Total' }
      )
    },
  },
  methods: {
    getList() {
      this.isLoading = true
      this.localParams = { store_id: 1, currency: 'GBP' }
      for (const key in this.queryParams) {
        if (notNullStr(this.queryParams[key])) {
          this.localParams[key] = this.queryParams[key]
        }
      }
      axios
        .get(API_LIST.get.competitionReport, { params: this.localParams })
        .then((res) => {
          this.items = res?.data?.data || []
          this.$store.dispatch('request/mutateState', { property: 'competitionReport', with: res?.data?.data })
        })
        .finally(() => (this.isLoading = false))
    },
    onReset() {
      this.queryParams = { ...this.initialParams }
      this.getList()
    },
    csvPreProcess(data) {
      const keys = this.fields.map((x) => x.key)
      for (let i in data) {
        for (const key in data[i]) {
          if (!keys.includes(key)) {
            delete data[i][key]
          }
        }
      }
    },
    onExport() {
      if (this.items.length > 0) {
        let headings = []
        let headingsFormat = [[]]
        this.fields.forEach((x, i) => {
          headings.push(x.key)
          headingsFormat[0].push(x.label + (['13', '14'].includes(String(i)) ? ' (%)' : ''))
        })
        let data = [...this.items]
        data.push(this.bottomRow)
        return exportAsExcel(
          `InstantWinLiveRevenueReport_${this.queryParams.start_date}-${this.queryParams.end_date}_${this.queryParams.timezone}`,
          null, // no need to fetch data from url because no pagination
          data, // pass data as params because no pagination
          this.items.length, // total
          this.csvPreProcess,
          headings,
          headingsFormat,
          (bool) => (this.isLoading = bool)
        )
      } else {
        this.$notify({ group: 'root', type: 'error', text: this.$t('notify.no_records') })
      }
    },
    formatWithCurrency(v) {
      return v ? Number(v).toLocaleString('en-US', { style: 'currency', currency: 'GBP', minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-'
    },
  },
  // queryParams will be initialized when ReportSearchInput created
  mounted() {
    this.initialParams = { ...this.queryParams }
    this.getList()
  },
}
</script>
