<template>
  <b-modal
    lazy
    centered
    id="product-lookup"
    ref="product-lookup"
    :title="$t('product.product_lookup')"
    title-class="w-100 d-flex justify-content-center align-self-center"
    header-class="py-2"
    body-class="pt-0"
    dialog-class="product-lookup-modal"
    hide-footer
    @show="showLookup"
    @hidden="hideLookup">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <ProductSearchInput :queries.sync="queries" :getList="getList" :isLoading.sync="isLoading" />
    <!-- @row-clicked="lookupType == 'liveDraw' ? rowClicked : null" -->
    <b-table :fields="lookupFields" :items="lookUpItems" show-empty :empty-text="$t('notify.table_no_records')" small hover responsive sticky-header="60vh" head-variant="light" class="m-0 p-0">
      <template #head()="{ label, field }">{{ label }}<b-icon-question-circle-fill v-if="field.tooltip" class="ml-1" v-b-tooltip.hover.topright="field.tooltip" /></template>
      <template #cell(name)="{ value, item }">
        <div v-if="item.nameLang" class="w-auto d-flex flex-row flex-nowrap align-items-center">
          <div class="action" @click="viewProdDetail(item)" style="white-space: normal; min-width: 8rem">{{ value }}</div>
          <icon-translate :onClick="() => pushProductEdit(item)" classes="pl-1" />
        </div>
        <div v-else class="action" @click="viewProdDetail(item)" style="white-space: normal; min-width: 12rem">{{ value }}</div>
      </template>
      <template #cell(store_id)="{ value }"><store-badge :store_id="value" /></template>

      <template #cell(action)="{ item }">
        <slot name="action" :item="item"></slot>
      </template>
    </b-table>
    <paginate v-if="lookUpItems.length > 0" :queries="queries" :fetcher="getList" :total="$store.getters['request/productList'].total" class="card-footer" />
  </b-modal>
</template>
<script>
import ProductSearchInput from '@/components/Product/ProductSearchInput.vue'
import routerMixin from '@/mixins/router-mixin'
import utilsMixin from '@/mixins/utils-mixin'
import { noTransFormatter } from '@/utils/index'
import { formatWithCurrency } from '@/utils/numberUtils'

export default {
  name: 'ProductLookup',

  mixins: [
    utilsMixin, // hasSlot
    routerMixin, // pushProductEdit
  ],

  components: {
    ProductSearchInput,
  },

  props: {
    lookupType: String,
    form: Object,
    viewProdDetail: Function,
    // availAmt: Function,
  },

  data() {
    return {
      isLoading: false,
      queries: {
        name: null,
        store_id: this.form.store_id,
        store_ids: `[${this.form.store_id}]`,
        available: 1, // all in stock
        page: 1,
        perPage: 10,
      },
    }
  },

  computed: {
    lookupFields() {
      return [
        { key: 'name', label: this.$t('product.product_name'), formatter: noTransFormatter, thStyle: 'width: 12rem' },
        {
          key: 'type',
          label: this.$t('general.type'),
          formatter: (v) => this.$t(`product.types.${v.replace('-', '_')}`),
          // tdAttr: { style: 'cursor: pointer' },
          tdClass: 'text-capitalize',
        },
        {
          key: 'store_id',
          label: this.$tc('general.store', 1),
          // tdAttr: { style: 'cursor: pointer' }
        },
        {
          key: 'cost',
          label: this.$t('general.cost'),
          formatter: (v, k, i) => formatWithCurrency(v, i.currency),
          class: 'text-right',
          // tdAttr: { style: 'cursor: pointer' }
        },
        {
          key: 'stock_amount',
          label: this.$t('product.amounts_label'),
          formatter: (v, k, i) => i.initial_amount + ' / ' + v + ' / ' + i.available_amount,
          // tdAttr: { style: 'cursor: pointer' },
          class: 'text-center',
          tooltip: this.$t('product.initial_amount') + ' / ' + this.$t('product.stock_amount') + ' / ' + this.$t('product.available_amount'),
        },
        { key: 'action', label: this.$tc('general.action', 1) },
      ]
    },
    lookUpItems() {
      return this.$store.getters['request/productList']?.data ?? []
    },
    types() {
      return this.$store.getters['request/productEntityList']?.reduce((arr, type) => (type?.includes('cash') ? arr : arr.concat(type)), [])
    },
  },

  watch: {
    'form.store_id'(newVal) {
      this.queries['store_id'] = newVal
    },
    'queries.store_id'(newVal, oldVal) {
      this.queries['store_ids'] = newVal ? `[${newVal}]` : null
    },
  },

  methods: {
    showLookup(evt) {
      this.queries.types = this.types
      this.queries.store_id = this.form.store_id
      this.getList()
    },
    hideLookup(evt) {
      this.$emit('update:lookupType', null)
    },
    getList(_params) {
      this.isLoading = true
      const params = { ..._params }
      for (const key in this.queries) {
        if (key !== 'store_id') {
          params[key] = this.queries[key]
        }
      }
      this.$store
        .dispatch('request/getProductList', params)
        .then(() => (this.isLoading = false))
        .catch(() => (this.isLoading = false))
    },
    // rowClicked(item, index, evt) {
    //   if (evt.target.cellIndex > 0) {
    //     if (!this.isSelectedLiveDraw(item)) {
    //       this.selectLiveDrawProd(item)
    //     } else if (!this.noRemoveByStatus) {
    //       this.rmLiveDrawProd(item)
    //     }
    //   }
    // },
  },
}
</script>
