<template>
  <div style="display: contents">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />

    <div v-if="detail" class="font-weight-bold">
      {{ $t('user.account_details') }}
      <bool-badge :value="detail.is_active" :text="detail.is_active == 1 ? $t('status.active') : $t('status.inactive')" class="ml-2" />
      <store-badge v-if="detail.store_id" :store_id="detail.store_id" class="ml-1" />
    </div>

    <div class="mt-3">
      <span class="text-sm">{{ $t('general.id') }}</span>
      <span class="ml-1 text-danger">*</span>
      <span class="ml-3 font-weight-light">{{ detail.id }}</span>
    </div>

    <b-row cols="1" cols-xl="2" v-if="detail">
      <b-col class="pr-xl-4">
        <div class="mt-3">
          <span class="text-sm">{{ $t('user.credit_balance') }}</span>
          <b-icon-info-circle-fill v-b-tooltip.hover.topright="'Total / Available / Pending'" class="ml-1 text-sm" />
          <balance-span :available="detail.balance_amount" :pending="detail.balance_amount_pending" cls="ml-3 font-weight-light" />
        </div>
        <div class="mt-3">
          <span class="text-sm">{{ $t('general.username') }}</span>
          <span class="ml-1 text-danger">*</span>
          <span class="ml-3 font-weight-light">{{ detail.name }}</span>
        </div>
        <div class="mt-3">
          <span class="text-sm">{{ $t('user.first_name') }}</span>
          <span class="ml-1 text-danger">*</span>
          <span class="ml-3 font-weight-light">{{ detail.profile ? detail.profile.first_name : $t('no_data') }}</span>
        </div>
        <div class="mt-3">
          <span class="text-sm">{{ $t('general.email') }}</span
          ><span class="ml-1 text-danger">*</span>
          <span class="ml-3 font-weight-light">{{ detail.email }}</span>
        </div>

        <template v-if="hasSlot('phone')">
          <slot name="phone" :val="detail.profile ? detail.profile.phone_num : null"></slot>
        </template>
        <div class="mt-3" v-else>
          <span class="text-sm">{{ $t('user.phone') }}</span>
          <span class="ml-1 text-danger">*</span>
          <span class="ml-3 font-weight-light">{{ detail.profile ? detail.profile.phone_num : $t('no_data') }}</span>
        </div>

        <div class="mt-3">
          <span class="text-sm">{{ $t('user.registered_at') }}</span>
          <span class="ml-3 font-weight-light">{{ formatLocalDateTime(detail.created_at) }}</span>
        </div>
      </b-col>
      <b-col class="pl-xl-4">
        <div class="mt-3">
          <span class="text-sm">{{ $t('user.cash_balance') }}</span>
          <b-icon-info-circle-fill v-b-tooltip.hover.topright="'Total / Available / Pending'" class="ml-1 text-sm" />
          <balance-span :available="detail.wallet_amount" :pending="detail.wallet_amount_pending" cls="ml-3 font-weight-light" />
        </div>
        <div class="mt-3">
          <span class="font-weight-normal text-sm">{{ $t('user.display_name') }}</span>
          <span class="ml-1 text-danger">*</span>
          <span class="ml-3 font-weight-light">{{ detail.display_name }}</span>
        </div>
        <div class="mt-3">
          <span class="text-sm">{{ $t('user.last_name') }}</span>
          <span class="ml-1 text-danger">*</span>
          <span class="ml-3 font-weight-light">{{ detail.profile ? detail.profile.last_name : $t('no_data') }}</span>
        </div>
        <div class="mt-3">
          <span class="text-sm">{{ $t('user.email_verified_at') }}</span>
          <span class="ml-3 font-weight-light">{{ detail.email_verified_at ? formatLocalDateTime(detail.email_verified_at) : 'Email not verified' }}</span>
        </div>
        <div class="mt-3">
          <span class="text-sm">Register IP</span>
          <span class="ml-3 font-weight-light">{{ detail.register_ip }}</span>
        </div>
        <div class="mt-3">
          <span class="text-sm">{{ $t('user.last_update_at') }}</span>
          <span class="ml-3 font-weight-light">{{ formatLocalDateTime(detail.updated_at) }}</span>
        </div>
      </b-col>
    </b-row>
    <template v-if="detail && hasSlot('address')">
      <slot name="address" :user="detail" :addresses="detail.addresses" :address="detail.addresses ? detail.addresses[0] : null"></slot>
    </template>
  </div>
</template>
<script>
import utilsMixin from '@/mixins/utils-mixin';
import axios from '@/plugins/axios';
import { API_LIST } from '@/utils/consts';
import { formatLocalDateTime } from '@/utils/dateTimeUtils';

export default {
  name: 'UserDetails',
  mixins: [utilsMixin],
  props: { user: Object },
  data() {
    return {
      isLoading: true,
      details: null,
    }
  },
  computed: {
    detail: {
      get() {
        return { ...this.user }
      },
      set: (v) => v,
    },
  },
  methods: {
    formatLocalDateTime,
    getUserDetails(user_id) {
      this.isLoading = true
      axios
        .get(API_LIST.get.userDetails, { params: { user_id } })
        .then((res) => {
          if (res?.data?.data) {
            this.$store?.dispatch?.('request/mutateState', { property: 'userDetails' + user_id, with: res?.data?.data })
            for (const key in res.data.data) {
              this.$set(this.detail, key, res.data.data[key])
            }
          }
        })
        .finally(() => (this.isLoading = false))
    },
  },
  mounted() {
    if (this.user?.id) {
      this.getUserDetails(this.user.id)
    }
  },
}
</script>
