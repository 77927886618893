var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card',{staticClass:"table-custom",attrs:{"body-classes":"p-0 d-flex flex-column"}},[_c('loading',{attrs:{"active":_vm.isLoading,"can-cancel":false,"is-full-page":false}}),_c('WinnerSearchInput',{attrs:{"queryParams":_vm.queryParams,"onSearch":_vm.onSearch,"isLoading":_vm.isLoading},on:{"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}}}),_c('b-table',{staticClass:"m-0 p-0",attrs:{"fields":_vm.fields,"items":_vm.items,"busy":_vm.isLoading,"show-empty":"","empty-text":_vm.$t('notify.table_no_records'),"small":"","hover":"","responsive":"","sticky-header":"100%","head-variant":"light","primary-key":"id"},scopedSlots:_vm._u([{key:"head()",fn:function(ref){
var label = ref.label;
var field = ref.field;
return [_vm._v(_vm._s(label)),(field.tooltip)?_c('b-icon-question-circle-fill',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.topright",value:(field.tooltip),expression:"field.tooltip",modifiers:{"hover":true,"topright":true}}],staticClass:"ml-1"}):_vm._e()]}},{key:"cell(id)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [(value)?_c('router-link',{staticClass:"action text-decoration-none d-block",attrs:{"to":{ name: 'Winner Details', query: { id: item.id }, params: { winner: item } }}},[_vm._v(" "+_vm._s(value)+" ")]):[_vm._v("-")]]}},{key:"cell(title)",fn:function(ref){
var value = ref.value;
return [_c('tooltip-span',{attrs:{"content":value}})]}},{key:"cell(competition_reward)",fn:function(ref){
var value = ref.value;
return [(value.product && value.product.name)?_c('tooltip-span',{attrs:{"content":value.product.name}}):[(Number(value.alternative_cash_amount) > 0)?_c('b-badge',{staticClass:"d-block",staticStyle:{"max-width":"max-content"},attrs:{"pill":"","variant":"success"}},[_vm._v("CASH")]):_vm._e(),(Number(value.alternative_credit_amount) > 0)?_c('b-badge',{staticClass:"d-block",staticStyle:{"max-width":"max-content"},attrs:{"pill":"","variant":"primary"}},[_vm._v("CREDIT")]):_vm._e()]]}},{key:"cell(tracking_status)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('b-badge',{attrs:{"pill":"","variant":['secondary', 'success'][value]}},[_vm._v(_vm._s(_vm.$t('winner.tracking_status_list')[value]))]),(_vm.remindCreateInvoice(item))?_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(_vm.$t('winner.no_receipt')),expression:"$t('winner.no_receipt')",modifiers:{"hover":true,"right":true}}],class:("fa fa-receipt text-xl " + (_vm.overTwoWeeks(item)) + " flex-grow-0 ml-2"),staticStyle:{"cursor":"pointer","width":"20px"},on:{"click":function($event){return _vm.viewEdit(item)}}}):_vm._e()]}},{key:"cell(image)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [(value)?_c('b-img-lazy',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:(value),expression:"value",modifiers:{"hover":true,"left":true}}],staticClass:"hover-image",staticStyle:{"max-height":"60px"},attrs:{"src":value,"alt":_vm.shortenString(value, 13, true),"block":"","rounded":""}}):_c('b-badge',{staticClass:"font-weight-bold",staticStyle:{"cursor":"pointer"},attrs:{"variant":"danger"},on:{"click":function($event){return _vm.viewEdit(item)}}},[_vm._v("⚠ "+_vm._s(_vm.$t('notify.no_image')))])]}},{key:"cell(competition)",fn:function(ref){
var value = ref.value;
return [_vm._l((value),function(val,index){return [(val)?_c('store-badge',{key:'status-store-' + index,class:("d-block" + (index > 0 ? ' mt-1' : '')),attrs:{"store_id":val}}):_vm._e()]})]}},{key:"cell(order_id)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [(value)?_c('router-link',{staticClass:"action text-decoration-none",attrs:{"to":{ name: 'Order Details', query: { orderDetailsId: value, user: item.user } }}},[_vm._v(" "+_vm._s('#' + value)+" ")]):_c('b-badge',{attrs:{"pill":"","variant":"danger"}},[_vm._v(_vm._s(_vm.$t('order.no_order')))])]}},{key:"cell(alternative_cash_amount)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [(value != '-')?_c('span',{staticClass:"action",on:{"click":function($event){return _vm.viewConvertPrize(item, 'alternative_cash_amount')}}},[_vm._v(_vm._s(value))]):[_vm._v("-")]]}},{key:"cell(alternative_credit_amount)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [(value != '-')?_c('span',{staticClass:"action",on:{"click":function($event){return _vm.viewConvertPrize(item, 'alternative_credit_amount')}}},[_vm._v(_vm._s(value))]):[_vm._v("-")]]}},{key:"cell(user)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [(value)?_c('span',{staticClass:"action",on:{"click":function($event){return _vm.viewUser(item)}}},[_vm._v(_vm._s(value.name))]):_c('span',[_vm._v(_vm._s(item.user_id ? '#' + item.user_id : ''))]),_c('store-badge',{staticClass:"ml-1",attrs:{"store_id":value.store_id}})]}},{key:"cell(status)",fn:function(ref){
var value = ref.value;
return [(value !== 2)?_c('b-badge',{attrs:{"pill":"","variant":['danger', 'secondary', 'primary', 'info', 'success'][value + 1]}},[_vm._v(_vm._s(_vm.$t(("winner.status_list[" + (value + 1) + "]"))))]):_vm._e()]}},{key:"cell(action)",fn:function(ref){
var item = ref.item;
return [_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.topleft",value:(_vm.$t('action.edit')),expression:"$t('action.edit')",modifiers:{"hover":true,"topleft":true}}],on:{"click":function($event){return _vm.viewEdit(item)}}},[_c('b-icon-pencil')],1),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.topright",value:(_vm.$t('competition.convert_prize')),expression:"$t('competition.convert_prize')",modifiers:{"hover":true,"topright":true}}],attrs:{"disabled":_vm.disableConvertPrize(item)},on:{"click":function($event){return _vm.viewConvertPrize(item)}}},[_c('b-icon-cash-coin')],1)]}}])}),(_vm.items.length > 0)?_c('paginate',{staticClass:"card-footer",attrs:{"queries":_vm.queryParams,"fetcher":_vm.getWinnerList,"total":_vm.winnerList.total}}):_vm._e(),(_vm.items.length > 0)?_c('WinnerEdit',{attrs:{"winner":_vm.actionTarget,"getList":_vm.getWinnerList},on:{"update:winner":function($event){_vm.actionTarget=$event}}}):_vm._e(),(_vm.items.length > 0)?_c('ConvertPrize',{attrs:{"winner":_vm.actionTarget,"getList":_vm.getWinnerList,"readonly":_vm.readonly},on:{"update:winner":function($event){_vm.actionTarget=$event}}}):_vm._e(),_c('b-modal',{attrs:{"lazy":"","centered":"","id":"user-details","title":_vm.$t('user.user_details', { id: '' }),"title-class":"w-100 d-flex justify-content-center align-self-center","header-class":"py-2","body-class":"pt-0","hide-footer":""},on:{"hidden":function($event){_vm.actionTarget = null}}},[(_vm.actionTarget && _vm.actionTarget.user)?_c('UserDetails',{attrs:{"user":_vm.actionTarget.user},scopedSlots:_vm._u([{key:"address",fn:function(ref){
var address = ref.address;
return [(address)?_c('br'):_vm._e(),(address)?_c('AddressDetails',{attrs:{"userStore":_vm.actionTarget.store_id,"address":address}}):_vm._e()]}}],null,false,1461139663)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }