<template>
  <div style="display: contents">
    <LiveDrawSetting
      :lookupType.sync="lookupType"
      :form.sync="form"
      :prodStoreValid="prodSameStore"
      :viewProdDetail="viewProdDetail"
      :instantWinProdAmt="instantWinProdAmt"
      :subtotal="liveDrawSubtotal"
      :instantWinTotal="instantWinSubtotal.all">
      <!-- :noEditByStatus="noEditByStatus" -->
      <!-- :prodAmtValid="prodAmtAvail" -->
      <template #action="{ item }">
        <b-button variant="outline-danger" size="xs" @click="rmLiveDrawProd(item)"><b-icon-trash /></b-button>
        <!-- :disabled="noRemoveByStatus" -->
      </template>
    </LiveDrawSetting>

    <slot></slot>

    <InstantWinSetting
      v-if="($cookies.get('Store') == 1 || selfStore == 1) && form.store_id == 1"
      :lookupType.sync="lookupType"
      :form.sync="form"
      :noEditAfterPublish="noEditAfterPublish"
      :viewProdDetail="viewProdDetail"
      :instantWinProdDetail.sync="instantWinProdDetail"
      :prodStoreValid="prodSameStore"
      :subtotal="instantWinSubtotal" />
    <!-- :availAmt="availAmt" -->
    <!-- :isExistingTicket="isExistingTicket" -->

    <ProductLookup :lookupType.sync="lookupType" :form="form" :viewProdDetail="viewProdDetail">
      <!-- :availAmt="availAmt" -->
      <template #action="{ item }">
        <b-button v-if="lookupType === 'instantWin'" :disabled="!isAvailable(item)" variant="outline-primary" @click="selectInstantWinProd(item)">
          {{ $t('action.select') }}
        </b-button>
        <template v-else-if="lookupType === 'liveDraw'">
          <b-button v-if="isSelectedLiveDraw(item)" variant="outline-primary" @click="selectLiveDrawProd(item)">
            {{ $t('action.add') }}
          </b-button>
          <b-button v-else variant="outline-primary" @click="selectLiveDrawProd(item)">{{ $t('action.select') }}</b-button>
        </template>
      </template>
    </ProductLookup>

    <b-modal
      lazy
      centered
      id="product-details"
      :title="$t('product.product_details', { id: productDetail ? ' #' + productDetail.id : '' })"
      title-class="w-100 d-flex justify-content-center align-self-center"
      header-class="py-2"
      body-class="pt-0"
      dialog-class="product-details-modal"
      hide-footer>
      <ProductDetails v-if="productDetail" :product="productDetail" />
    </b-modal>
  </div>
</template>
<script>
import InstantWinSetting from '@/components/Competitions/InstantWinSetting.vue'
import LiveDrawSetting from '@/components/Competitions/LiveDrawSetting.vue'
import ProductLookup from '@/components/Competitions/ProductLookup.vue'
import ProductDetails from '@/components/Product/ProductDetails.vue'
import { noTransFormatter } from '@/utils/index'
import { floatAddition, formatWithCurrency } from '@/utils/numberUtils'
import { mapGetters } from 'vuex'

export default {
  name: 'PrizeSetting',

  components: {
    LiveDrawSetting,
    InstantWinSetting,
    ProductLookup,
    ProductDetails,
  },

  props: {
    initVal: Object,
    form: Object,
    noEditAfterPublish: Boolean,
    prodAmtValid: Boolean || null,
    prodStoreValid: Boolean || null,
    imgItems: Array,
    stores: Array,
    resetShortName: Function,
  },

  data() {
    return {
      productDetail: null,
      instantWinProdDetail: null,
      lookupType: null,
    }
  },

  computed: {
    ...mapGetters({
      selfStore: 'store',
      // allowMultiStores: 'allowMultiStores',
    }),
    isDrawingInstantWin() {
      return this.initVal?.rewards?.some?.((item) => item?.type == 'consolation') && this.form?.status == 2
    },
    noEditByStatus() {
      return this.isDrawingInstantWin ? false : this.noEditAfterPublish
    },
    // noRemoveByStatus() {
    //   return this.isDrawingInstantWin ? this.form.liveDraw.length < 2 : this.noEditAfterPublish
    // },
    prodSameStore: {
      get() {
        let val = null
        if (this.form.liveDraw.length > 0) {
          const compareTarget = this.form.allow_multi_stores ? this.form.liveDraw[0].store_id : this.form.store_id
          val = this.form.liveDraw.every((item) => item.store_id === compareTarget)
        }
        this.$emit('update:prodStoreValid', val)
        return val
      },
      set(val) {
        if (!val) {
          this.form['instantWin'] = []
        }
        this.$emit('update:prodStoreValid', val)
        return val
      },
    },
    prodAmtAvail: {
      get() {
        let val = null
        if (this.form.liveDraw.length > 0) {
          val = this.noEditByStatus || this.form.liveDraw.every((item) => !item.id || (item.amount > 0 && item.available_amount > 0 && this.availAmt(item) >= 0))
        }
        this.$emit('update:prodAmtValid', val)
        return val
      },
      set(val) {
        this.$emit('update:prodAmtValid', val)
        return val
      },
    },
    liveDrawSubtotal() {
      return this.subtotal('liveDraw')
    },
    instantWinSubtotal() {
      return this.subtotal('instantWin')
    },
  },

  methods: {
    viewProdDetail(obj) {
      this.productDetail = obj
      this.$bvModal.show('product-details')
    },
    liveDrawProd(prod) {
      return this.form.liveDraw?.find?.((item) => item.id == prod.id)
    },
    instantWinProdAmt(prod) {
      return this.form.instantWin.reduce((acc, item) => (item.id && item.id == prod.id ? (acc += +item.amount) : acc), 0) || 0
    },
    availAmt(prod) {
      const liveDraw = this.liveDrawProd(prod)
      const instantWin = this.instantWinProdAmt(prod)
      return liveDraw ? prod.available_amount - liveDraw.amount - instantWin : prod.available_amount - instantWin
    },
    isAvailable(prod) {
      return this.availAmt(prod) > 0
    },
    // isExistingTicket(number) {
    //   return this.form.instantWin.some((item) => String(item.ticket_number) == String(number))
    // },
    // isSelectedInstantWin(prod) {
    //   return this.form.instantWin.some((item) => item.id && item.id == prod.id)
    // },
    isSelectedLiveDraw(prod) {
      return this.form.liveDraw.some((item) => item.id === prod.id)
    },
    selectLiveDrawProd(prod) {
      if (this.isAvailable(prod)) {
        // translation
        const formatted = { ...prod, name: noTransFormatter(prod.name, 'name', prod), description: noTransFormatter(prod.description, 'description', prod), amount: 1, must_go: 1 }
        // reset form title, descr and short name
        if (!this.form?.liveDraw?.[0]) {
          this.resetComp(formatted)
        }
        // add product to form
        this.form.liveDraw.push(formatted)
        // add product images to form images
        const imgs = prod.attributes.filter(({ code }) => code == 'image')
        imgs.sort((a, b) => a.position - b.position)
        imgs.forEach(({ status, value }) => {
          this.imgItems.push({
            product_id: prod.id,
            id: null,
            status: status,
            src: value,
            position: this.imgItems.length + 1,
            size: NaN,
            uploaded: true,
            file: null,
            name: value.substring(value.lastIndexOf('/') + 1),
            store_id: prod.store_id,
          })
        })
      }
    },
    rmLiveDrawProd(prod) {
      const index = this.form.liveDraw.findIndex((item) => (prod.isCash ? item?.isCash == prod.isCash : item?.id == prod.id))
      this.form.liveDraw.splice(index, 1)
      if (index == 0) {
        this.resetComp(this.form.liveDraw[0])
      }
      // remove product images from images items, and reset remaining images' position
      const len = this.imgItems.length
      for (let i = 0; i < len; i++) {
        const toRemove = this.imgItems.findIndex((x) => x.product_id && x.product_id == prod.id)
        if (toRemove > -1) {
          this.imgItems.splice(toRemove, 1)
        }
      }
      this.imgItems.forEach((x, i) => (x.position = i + 1))
    },
    resetComp(prod) {
      this.form['title'] = prod?.name
      this.form['description'] = prod?.description
      this.stores.forEach((store) => {
        this.$set(store, 'title', prod?.name)
        this.$set(store, 'description', prod?.description)
      })
      this.resetShortName(prod?.name, this.stores[0].end_time)
    },
    selectInstantWinProd(prod) {
      this.instantWinProdDetail = { ...prod, name: noTransFormatter(prod.name, 'name', prod), description: noTransFormatter(prod.description, 'description', prod) }
      this.$bvModal.hide('product-lookup')
    },
    subtotal(type) {
      return this.form[type].reduce((res, item, index, arr) => {
        if (item.id) {
          res['prod'] = floatAddition(res.prod, (item.cost || 0) * (item.amount || 1))
        } else if (item.isCash) {
          res['cash'] = floatAddition(res.cash, item.cost)
        } else {
          res['credit'] = floatAddition(res.credit, item.cost)
        }
        if (index == arr.length - 1) {
          res['all'] = floatAddition(floatAddition(res.prod, res.cash), res.credit)
          ;['prodFormatted', 'cashFormatted', 'creditFormatted', 'allFormatted'].forEach((key) => (res[key] = formatWithCurrency(res[key.replace('Formatted', '')], this.form.currency, '-')))
        }
        return res
      }, {})
    },
  },

  created() {
    // get product types
    if (!this.$store.getters['request/productEntityList']) {
      this.isLoading = true
      this.$store
        .dispatch('request/getProductEntityList')
        .then(() => (this.isLoading = false))
        .catch(() => (this.isLoading = false))
    }
  },
}
</script>
