export default {
  data() {
    return {
      frequencyOpts: ['weekly', 'monthly'],
    }
  },
  methods: {
    getExistingMultiPay(product) {
      let multiPay = null
      if (product?.type == 'cash_multiple' && product?.attributes?.length > 0) {
        multiPay = product.attributes.find(({ code }) => this.frequencyOpts.includes(code))
      }
      return multiPay
    },
  },
}
