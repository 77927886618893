<template>
  <b-badge @click="onClick" :variant="count && count.published >= count.total ? 'success' : 'light'" pill class="d-block uppercase" :style="onClick ? 'cursor: pointer' : ''">
    {{ count ? `Winner Published ${count.published} / ${count.total}` : 'Winner Not Published' }}
  </b-badge>
</template>
<script>
export default {
  name: 'WinnerStatus',

  props: {
    value: Object,
    onClick: Function,
  },

  computed: {
    count() {
      if (this.value) {
        let published = 0
        let total = 0
        for (const key in this.value) {
          total += 1
          if (this.value[key] == 1) {
            published += 1
          }
        }
        return { published, total }
      } else {
        return null
      }
    },
  },
}
</script>
