var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"product-lookup",attrs:{"lazy":"","centered":"","id":"product-lookup","title":_vm.$t('product.product_lookup'),"title-class":"w-100 d-flex justify-content-center align-self-center","header-class":"py-2","body-class":"pt-0","dialog-class":"product-lookup-modal","hide-footer":""},on:{"show":_vm.showLookup,"hidden":_vm.hideLookup}},[_c('loading',{attrs:{"active":_vm.isLoading,"can-cancel":false,"is-full-page":false}}),_c('ProductSearchInput',{attrs:{"queries":_vm.queries,"getList":_vm.getList,"isLoading":_vm.isLoading},on:{"update:queries":function($event){_vm.queries=$event},"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}}}),_c('b-table',{staticClass:"m-0 p-0",attrs:{"fields":_vm.lookupFields,"items":_vm.lookUpItems,"show-empty":"","empty-text":_vm.$t('notify.table_no_records'),"small":"","hover":"","responsive":"","sticky-header":"60vh","head-variant":"light"},scopedSlots:_vm._u([{key:"head()",fn:function(ref){
var label = ref.label;
var field = ref.field;
return [_vm._v(_vm._s(label)),(field.tooltip)?_c('b-icon-question-circle-fill',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.topright",value:(field.tooltip),expression:"field.tooltip",modifiers:{"hover":true,"topright":true}}],staticClass:"ml-1"}):_vm._e()]}},{key:"cell(name)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [(item.nameLang)?_c('div',{staticClass:"w-auto d-flex flex-row flex-nowrap align-items-center"},[_c('div',{staticClass:"action",staticStyle:{"white-space":"normal","min-width":"8rem"},on:{"click":function($event){return _vm.viewProdDetail(item)}}},[_vm._v(_vm._s(value))]),_c('icon-translate',{attrs:{"onClick":function () { return _vm.pushProductEdit(item); },"classes":"pl-1"}})],1):_c('div',{staticClass:"action",staticStyle:{"white-space":"normal","min-width":"12rem"},on:{"click":function($event){return _vm.viewProdDetail(item)}}},[_vm._v(_vm._s(value))])]}},{key:"cell(store_id)",fn:function(ref){
var value = ref.value;
return [_c('store-badge',{attrs:{"store_id":value}})]}},{key:"cell(action)",fn:function(ref){
var item = ref.item;
return [_vm._t("action",null,{"item":item})]}}],null,true)}),(_vm.lookUpItems.length > 0)?_c('paginate',{staticClass:"card-footer",attrs:{"queries":_vm.queries,"fetcher":_vm.getList,"total":_vm.$store.getters['request/productList'].total}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }