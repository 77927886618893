<template>
  <b-modal
    id="assign-prize"
    :title="$t('competition.assign_prize')"
    lazy
    centered
    title-class="w-100 d-flex justify-content-center align-self-center"
    header-class="py-2"
    footer-class="mt-2 py-2"
    body-class="py-0"
    @shown="shown"
    :loading="isLoading">
    <slot></slot>

    <form v-if="compDetail" :id="'assign-prize-' + tabIndex" @submit.prevent="submit">
      <div class="w-100 d-flex flex-row flex-nowrap align-items-center mb-2">
        {{ $t('competition.comp_title') }}:
        <span class="font-weight-bold ml-1 mr-3">{{ compDetail.title }}</span>
        <icon-translate v-if="compDetail.titleLang" classes="mr-3" />
        {{ $t('competition.comp_id') }}:
        <span class="font-weight-bold ml-1 mr-3">{{ compDetail.id }}</span>
        <CompetitionStatus :value="compDetail.status" />
      </div>

      <b-tabs v-model="tabIndex" lazy class="mt-4">
        <b-tab :title="tabNames[0]" :disabled="tabDisabled[0]">
          <p class="mt-4 mb-0">⚠️ Total number of live draw winner(s): {{ compDetail.live_winner_amt }}</p>
          <p class="mt-1 mb-0">Step 1: Please tick all prizes won by the winner.</p>
          <p class="mt-1 mb-0">Step 2: Please input and search the winning ticket number for the winner.</p>
        </b-tab>
        <b-tab :title="tabNames[1]" :disabled="tabDisabled[1]">
          <p class="mt-4 mb-0">⚠️ Please tick the prize first to set status to contacted.</p>
        </b-tab>
        <b-tab :title="tabNames[2]" :disabled="tabDisabled[2]">
          <p class="mt-4 mb-0">⚠️ Please tick the prize first to select the assigned type and set the related info.</p>
        </b-tab>
        <b-tab :title="tabNames[3]" :disabled="tabDisabled[3]">
          <p class="mt-4 mb-0">⚠️ Please tick the prize first to set the received documents.</p>
        </b-tab>

        <b-table
          :fields="rwdFields"
          :items="rwdItems"
          :busy="isLoading"
          primary-key="id"
          show-empty
          small
          hover
          responsive
          ref="rwdTable"
          selectable
          :select-mode="tabIndex < 2 ? 'multi' : 'single'"
          @row-selected="selectRwd"
          head-variant="light"
          sticky-header="300px"
          class="mt-4 mb-0 mx-0 p-0">
          <template v-if="tabIndex < 2" #head(action)>
            <div class="d-flex flex-row align-items-center">
              <b-form-checkbox v-model="selectAll" @input="selectAllRwd" />
              <span style="margin-left: -20px">All</span>
            </div>
          </template>
          <template #cell(action)="{ rowSelected, index, item }">
            <b-form-checkbox
              :checked.sync="rowSelected"
              :disabled="item.status != RWD_STATUS_LIST[tabIndex]"
              @input="$refs.rwdTable[item.status == RWD_STATUS_LIST[tabIndex] && $event ? 'selectRow' : 'unselectRow'](index)" />
          </template>
          <template #cell(status)="{ rowSelected, value, item }">
            <PrizeStatus :value="value" />
            <template v-if="[item.filled, rowSelected, item.status == RWD_STATUS_LIST[2] && item.assign_type][tabIndex]">
              {{ '-->' }}
              <PrizeStatus :value="value" next />
            </template>
          </template>
          <template #cell(user)="{ value }">
            <span v-if="value" class="action" @click="viewUser(value)">{{ value.name }}</span>
            <b-badge v-else pill variant="danger">{{ $t('user.no_user') }}</b-badge>
          </template>
          <template #cell(order)="{ value, item }">
            <router-link v-if="value" :to="linkToOrder(value, item)" class="action text-decoration-none">
              {{ '#' + value.id }}
            </router-link>
            <b-badge v-else pill variant="danger">{{ $t('order.no_order') }}</b-badge>
          </template>
          <template #cell(order.type)="{ value }"><TicketType :value="value" /></template>
        </b-table>

        <!-- Tickets -->
        <template v-if="tabIndex == 0">
          <div class="mt-4 w-100 d-flex flex-row flex-wrap align-items-center">
            <b-form-input
              v-model="queryParams['numbers']"
              :disabled="!rwdTicked"
              type="search"
              :placeholder="$tc('order.ticket_number', 1)"
              @keyup.enter="searchTix"
              style="width: 136px"
              class="mt-0 mb-2 mr-2" />
            <b-button variant="basic" class="mt-0 mb-2" @click="searchTix" :disabled="!rwdTicked">Search Ticket</b-button>
            <b-button variant="reset" class="mt-0 mb-2" @click="resetTix" :disabled="!rwdTicked"><b-icon-x class="mr-1" />{{ $t('action.reset') }}</b-button>
          </div>
          <p v-if="tixErr || (tixTicked && tixTicked.user_id == 1)" class="m-0 text-sm text-danger font-weight-normal">⚠️ The user, redra.w, is not valid.</p>
          <b-table
            :fields="tixFields"
            :items="ticketList ? ticketList.data : []"
            :busy="isLoading"
            primary-key="id"
            show-empty
            small
            hover
            responsive
            ref="tixTable"
            selectable
            select-mode="single"
            @row-selected="selectTix"
            head-variant="light"
            sticky-header="300px"
            class="m-0 p-0">
            <template #cell(action)="{ rowSelected, item, index }">
              <b-form-checkbox :checked.sync="rowSelected" switch :disabled="item.user_id == 1" @input="$refs.tixTable[$event ? 'selectRow' : 'unselectRow'](index)" />
            </template>
            <template #cell(user)="{ value }">
              <span v-if="value" class="action" @click="viewUser(value)">{{ value.name }}</span>
              <b-badge v-else pill variant="danger">{{ $t('user.no_user') }}</b-badge>
            </template>
            <template #cell(order)="{ value, item }">
              <router-link v-if="value" :to="linkToOrder(value, item)" class="action text-decoration-none">
                {{ '#' + value.id }}
              </router-link>
              <b-badge v-else pill variant="danger">{{ $t('order.no_order') }}</b-badge>
            </template>
            <template #cell(type)="{ value }"><TicketType :value="value" /></template>
          </b-table>
        </template>

        <b-form-group v-if="tabIndex == 1 || tabIndex == 3" label="Comment (Optional)" class="mt-4 mb-0">
          <b-form-textarea v-model="comment" @input="inputComment" :placeholder="$t('input.enter_reason')" />
        </b-form-group>

        <b-form-group v-if="tabIndex == 2 && rwdTicked" :disabled="rwdDisabled" label="Assigned Type" class="mt-4">
          <b-form-radio-group v-model="rwdTicked['assign_type']">
            <b-form-radio :value="rwdTicked.prize ? rwdTicked.prize : 'settle'" class="allocate-checkbox w-100" style="height: 36px">
              <span class="flex-grow-0 flex-shrink-0 pt-1" style="width: 160px">{{ formatRwdName(rwdTicked.prize, 'Settle') }}</span>
            </b-form-radio>
            <b-form-radio v-if="rwdTicked.prize != 'cash'" value="cash" class="allocate-checkbox w-100 mt-2">
              <span class="flex-grow-0 flex-shrink-0 pt-1" style="width: 160px">Convert to{{ rwdTicked.prize == 'cash_multiple' ? ' one-off ' : ' ' }}cash</span>
              <b-input-group class="flex-grow-1 flex-shrink-1 pl-2 m-0">
                <b-form-select v-model="cashCurr" :options="currOpts" style="width: 80px; max-width: 80px" />
                <b-form-input
                  v-model="rwdTicked['assign_amount_cash']"
                  :required="rwdTicked['assign_type'] == 'cash'"
                  number
                  type="number"
                  :min="rwdTicked['assign_type'] == 'cash' ? 0.01 : 0.0"
                  step="0.01"
                  :placeholder="$t('competition.alt_cash_tip')"
                  class="px-1" />
              </b-input-group>
            </b-form-radio>
            <b-form-radio value="credit" class="allocate-checkbox w-100 mt-2">
              <span class="flex-grow-0 flex-shrink-0 pt-1" style="width: 160px">Convert to credit</span>
              <b-input-group class="flex-grow-1 flex-shrink-1 pl-2 m-0">
                <b-form-select v-model="creditCurr" :options="currOpts" style="width: 80px; max-width: 80px" />
                <b-form-input
                  v-model="rwdTicked['assign_amount_credit']"
                  :required="rwdTicked['assign_type'] == 'credit'"
                  number
                  type="number"
                  :min="rwdTicked['assign_type'] == 'credit' ? 0.01 : 0.0"
                  step="0.01"
                  :placeholder="$t('competition.alt_credit_tip')"
                  class="px-1" />
              </b-input-group>
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>

        <template v-if="tabIndex == 3">
          <b-table
            :caption-html="`<span class='text-dark'>Documents</span>`"
            caption-top
            :fields="imgFields"
            :items="rwdTicked['images']"
            :busy="isLoading"
            head-variant="light"
            show-empty
            small
            hover
            responsive
            class="mt-2 mb-0 mx-0 p-0">
            <template #cell(document)="{ value, item }">
              <span>{{ value }}</span>
              <span v-if="item.product ? item['type'] == 'receipt' : item['type'] == 'poa'" class="text-danger ml-1">*</span>
            </template>
            <template #cell(preview)="{ value, item }">
              <span v-if="item.loading">{{ $t('status.loading') }}</span>
              <b-img-lazy v-else-if="value" :src="value" fluid block rounded style="max-height: 4rem" class="hover-image" />
              <b-badge v-else variant="danger" class="font-weight-bold">⚠ NO IMAGE</b-badge>
            </template>
            <template #cell(file)="{ item }">
              <b-form-file :disabled="rwdDisabled" v-model="item['file']" @change="changeImage($event, item)" accept="image/*" size="sm" class="h-100" style="width: 172px" />
            </template>
            <template #cell(action)="{ item }">
              <b-button variant="outline-danger" size="xs" @click="clearImage(item)" :disabled="rwdDisabled || item['uploaded']">
                <b-icon-trash />
              </b-button>
            </template>
            <template #cell(content)="{ item }">
              <b-form-input :disabled="rwdDisabled" :value="item['content']" :required="item.product ? item['type'] == 'receipt' : item['type'] == 'poa'" @input="() => {}" type="url" />
            </template>
          </b-table>
        </template>
      </b-tabs>
    </form>

    <template #modal-footer="{ cancel }">
      <b-button variant="outline-secondary" @click="cancel">{{ $t('action.close') }}</b-button>
      <b-button variant="basic" type="submit" :form="'assign-prize-' + tabIndex" :disabled="isLoading || submitDisabled">{{ tabNames[tabIndex] }}</b-button>
    </template>
  </b-modal>
</template>
<script>
import CompetitionStatus from '@/components/Competitions/CompetitionStatus.vue'
import PrizeStatus from '@/components/PrizeStatus.vue'
import TicketType from '@/components/TicketType.vue'
import axios from '@/plugins/axios'
import { AwsSecurityUpdate, AWSSecurityWithURL } from '@/store/services/aws'
import { API_LIST, RWD_STATUS_LIST } from '@/utils/consts'
import { base64Encode } from '@/utils/fileUtils'
import { notifyError, notifySuccess } from '@/utils/index'
import { formatBytes, formatWithCurrency } from '@/utils/numberUtils'
import { mapGetters } from 'vuex'

export default {
  name: 'AssignPrize',

  components: {
    CompetitionStatus,
    TicketType,
    PrizeStatus,
  },

  props: { comp: Object },

  data() {
    const defCurr = ['GBP', 'MXN'][this.$store.getters['store'] - 1]
    return {
      RWD_STATUS_LIST,
      isLoading: false,
      compDetail: null,
      tabNames: ['Select Prize for Winner', 'Set Status to Contacted', 'Select the Assigned Type', 'Set Status to Received'],
      tabIndex: 0,
      tabDisabled: [true, true, true, true],
      rwdItems: [],
      rwdTicked: {},
      selectAll: false,
      queryParams: {}, // for ticket list search
      tixTicked: null,
      tixErr: false,
      comment: null,
      currOpts: ['GBP', 'MXN'],
      images: [
        { type: 'photoID', document: this.$t('winner.photo_id') },
        { type: 'poa', document: 'Proof of Address' },
        { type: 'receipt', document: this.$t('winner.receipt') },
      ],
      costCurr: defCurr,
      cashCurr: defCurr,
      creditCurr: defCurr,
    }
  },

  computed: {
    rwdDisabled() {
      return this.rwdTicked.status !== RWD_STATUS_LIST[this.tabIndex]
    },

    rwdFields() {
      const curr = this.rwdItems?.[0]?.currency
      return [
        { key: 'action', label: ' ', thClass: '' },
        { key: 'prize', label: this.$t('competition.prize_name'), formatter: (v) => this.formatRwdName(v) },
        { key: 'status', label: this.$t('general.status') },
        { key: 'cost', label: this.$t('general.cost'), formatter: (v) => formatWithCurrency(v, curr, '-') },
        { key: 'ticket_number', label: 'Ticket No.' },
        { key: 'user', label: this.$t('general.username') },
        { key: 'order', label: this.$t('order.order_id') },
        { key: 'order.type', label: this.$t('order.order_type') },
        { key: 'assign_type', label: 'Assigned Type', formatter: (v) => this.formatRwdName(v) },
        {
          key: 'assign_amount',
          label: 'Assigned Amount',
          formatter: (val, key, item) => formatWithCurrency(val || item[item.assign_type == item.prize ? 'cost' : 'assign_amount_' + item.assign_type], curr, '-'),
        },
        { key: 'contact_comment', formatter: (val, key, item) => val || item.user_reward_profiles?.find?.(({ type }) => type == key)?.content },
        { key: 'delivery_comment', formatter: (val, key, item) => val || item.user_reward_profiles?.find?.(({ type }) => type == key)?.content },
      ]
    },

    ...mapGetters({ ticketList: 'request/ticketList' }),

    tixFields() {
      return [
        { key: 'action', label: ' ' },
        { key: 'number', label: 'Ticket No.' },
        { key: 'user', label: this.$t('general.username') },
        { key: 'order', label: this.$t('order.order_id') },
        { key: 'type', label: this.$t('order.order_type') },
      ]
    },

    // tabDisabled() {
    //   return [0, 1, 2, 3].reduce((arr, i) => arr.concat(!this.rwdItems?.some?.(({ status }) => status == RWD_STATUS_LIST[i])), [])
    // },

    submitDisabled() {
      switch (this.tabIndex) {
        case 0:
          return !this.rwdItems?.every?.((item) => item.filled)
        case 2:
          return !this.rwdItems?.every?.((item) => item.assign_type && item[item.assign_type == item.prize ? 'cost' : 'assign_amount_' + item.assign_type] > 0)
        default:
          return null
      }
    },

    imgFields() {
      const tmp = [
        'document',
        'preview',
        { key: 'formatted_name', formatter: (v, k, i) => v || i.content?.slice(i.content?.lastIndexOf('/') + 1) },
        { key: 'content', label: 'Url' },
        { key: 'file', label: 'Upload New File' },
        'action',
        { key: 'size', formatter: (v, k, i) => (i.file ? formatBytes(i.file.size) : ' ') },
      ]
      return tmp
    },
  },

  methods: {
    shown() {
      if (this.comp?.id) {
        this.isLoading = true
        this.compDetail = this.comp
        this.tabIndex = 0
        this.tabDisabled = [true, true, true, true]
        this.rwdTicked = { images: [...this.images] }
        this.tixTicked = null
        this.tixErr = null
        this.comment = null
        // reset ticket list when competition id is changed
        if (this.comp?.id !== this.queryParams?.id) {
          this.resetTix()
        }
        this.getRwdItems()
      }
    },

    getRwdItems() {
      this.isLoading = true
      axios
        .get(API_LIST.get.prizeList, { params: { competition_id: this.comp?.id, type: 'winner', page: 1, perPage: 100 } })
        .then((res) => {
          let arr = []
          let index = 3
          res?.data?.data?.data?.forEach?.((item) => {
            arr.push({
              ...item,
              assign_type: RWD_STATUS_LIST.indexOf(item.status) >= 3 ? (item.convert_cash_amount > 0 ? 'cash' : item.convert_credit_amount > 0 ? 'credit' : item.prize) : null,
              assign_amount: RWD_STATUS_LIST.indexOf(item.status) >= 3 ? item.convert_cash_amount || item.convert_credit_amount > 0 || item.cost : null,
              assign_amount_cash: item.alternative_cash_amount,
              assign_amount_credit: item.alternative_credit_amount,
              images: this.images.reduce((arr, img) => {
                const existing = item.user_reward_profiles?.find(({ type }) => type == img.type)
                this.clearImage(img, existing)
                return arr.concat(img)
              }, []),
            })
            const i = RWD_STATUS_LIST.indexOf(item.status)
            if (i > -1 && i < 4 && this.tabDisabled[i]) {
              this.tabDisabled[i] = false
              index = i < index ? i : index
            }
          })
          this.rwdItems = arr
          this.tabIndex = index
          this.selectAllRwd(false)
          this.$store.dispatch('request/mutateState', { property: 'prizeList', with: res?.data?.data })
        })
        .catch((err) => notifyError(err, this.$t('notify.unknown_err')))
        .finally(() => (this.isLoading = false))
    },

    formatRwdName(v, fallback) {
      return this.$t('product.types')[v] || v || fallback
    },

    selectRwd($event) {
      this.rwdTicked = $event[0] || { images: [...this.images] }
      this.tixTicked = null
    },

    selectAllRwd($event) {
      this.$refs.rwdTable?.[$event ? 'selectAllRows' : 'clearSelected']()
    },

    fillInfo() {
      this.rwdItems?.forEach?.((item, i) => {
        if (this.$refs.rwdTable?.isRowSelected?.(i)) {
          this.$set(item, 'ticket_id', this.tixTicked?.id)
          this.$set(item, 'ticket_number', this.tixTicked?.number)
          this.$set(item, 'user', this.tixTicked?.user)
          this.$set(item, 'order', this.tixTicked?.order)
          this.$set(item, 'filled', !!this.tixTicked)
        }
      })
    },

    getTixList() {
      this.isLoading = true
      axios
        .get(API_LIST.get.ticketList, { params: { ...this.queryParams, numbers: `[${this.queryParams.numbers}]` } })
        .then((res) => {
          const data = res?.data?.data
          this.$store.dispatch('request/mutateState', { property: 'ticketList', with: data ?? null })
          if (data?.data?.length > 0) {
            const i = data.data.findIndex((item) => item.user_id != 1)
            if (i > -1) {
              this.tixErr = false
              setTimeout(() => {
                if (this.compDetail?.live_winner_amt == 1) {
                  this.selectAllRwd(true)
                }
              }, 10)
              setTimeout(() => this.$refs.tixTable?.selectRow?.(i), 20)
            } else {
              this.tixErr = true
            }
          }
        })
        .catch((err) => {
          notifyError(err, this.$t('notify.unknown_err'))
          this.$store.dispatch('request/mutateState', { property: 'ticketList', with: null })
        })
        .finally(() => (this.isLoading = false))
    },

    searchTix() {
      this.queryParams.page = 1
      this.getTixList()
    },

    resetTix() {
      this.queryParams.id = this.comp?.id || undefined
      this.queryParams.numbers = null
      this.$store.dispatch('request/mutateState', { property: 'ticketList', with: null })
    },

    selectTix($event) {
      this.tixTicked = $event[0]
      this.fillInfo()
    },

    linkToOrder(value, item) {
      return {
        name: 'Order Details',
        query: { orderDetailsId: value.id, userId: item.user.id, userEmail: item.user.email, display_name: item.user.display_name, useBalance: item.use_balance, ip: value.remote_ip },
      }
    },

    inputComment($event) {
      for (let i = 0; i < this.rwdItems?.length; i++) {
        if (this.$refs.rwdTable?.isRowSelected?.(i)) {
          this.$set(this.rwdItems[i], this.tabIndex == 1 ? 'contact_comment' : 'delivery_comment', $event)
        }
      }
    },

    submit() {
      switch (this.tabIndex) {
        case 0:
          this.selectPrize()
          break
        case 1:
          this.setContacted()
          break
        case 2:
          this.selectAssign()
          break
        case 3:
          this.setReceived()
          break
        default:
          break
      }
    },

    selectPrize() {
      this.isLoading = true
      const params = this.rwdItems?.reduce?.((arr, item) => arr.concat({ competition_reward_id: item.id, competition_ticket_id: item.ticket_id }), [])
      axios
        .post(API_LIST.post.selectWinner, { winners: params })
        .then((res) => {
          if (res?.status == 200) {
            notifySuccess(res, 'Select prize(s) for live draw winner(s) successfully.')
            this.tabIndex += 1
            this.getRwdItems()
          }
        })
        .catch((err) => notifyError(err, 'Failed to select prize(s) for live draw winner(s).'))
        .finally(() => (this.isLoading = false))
    },

    setContacted() {
      this.isLoading = true
      const params = this.rwdItems?.reduce?.((arr, item, i) => (this.$refs.rwdTable?.isRowSelected?.(i) ? arr.concat({ id: item.user_reward_id, contact_comment: item.contact_comment }) : arr), [])
      axios
        .post(API_LIST.post.contactWinner, { contacts: params })
        .then((res) => {
          if (res?.status == 200) {
            notifySuccess(res, `Set prize status to contacted successfully.`)
            this.tabIndex += 1
            this.getRwdItems()
          }
        })
        .catch((err) => notifyError(err, `Failed to set prize status to contacted.`))
        .finally(() => (this.isLoading = false))
    },

    selectAssign() {
      this.isLoading = true
      const params = this.rwdItems?.reduce?.(
        (arr, item) =>
          item.status == RWD_STATUS_LIST[2]
            ? arr.concat({
                competition_reward_id: item.id,
                reward_option: item.assign_type,
                reward_amount: item.product_id && item.assign_type == item.prize ? undefined : item[item.assign_type == item.prize ? 'cost' : 'assign_amount_' + item.assign_type],
                comment: '',
              })
            : arr,
        []
      )
      axios
        .post(API_LIST.post.setPrize, { rewards: params })
        .then((res) => {
          if (res?.status == 200) {
            notifySuccess(res, 'Select the assigned type successfully.')
            this.tabIndex += 1
            this.getRwdItems()
          }
        })
        .catch((err) => notifyError(err, 'Failed to select the assigned type.'))
        .finally(() => (this.isLoading = false))
    },

    async changeImage(event, item) {
      const file = event?.target?.files?.[0] || item.file || null
      if (file) {
        this.$set(item, 'loading', true)
        await base64Encode(file)
          .then((res) => {
            this.$set(item, 'preview', res)
            this.$set(item, 'content', res)
            this.$set(item, 'uploaded', false)
            this.$set(item, 'formatted_name', `${this.rwdTicked?.order?.id}_${this.rwdTicked?.id}_${item.type}${file.name.slice(file.name.lastIndexOf('.'))}`)
          })
          .finally(() => this.$set(item, 'loading', false))
      }
    },

    async clearImage(item, existing) {
      this.$set(item, 'id', existing?.id || null)
      this.$set(item, 'file', null)
      this.$set(item, 'content', existing?.content || null)
      this.$set(item, 'uploaded', existing ? true : false)
      this.$set(item, 'formatted_name', existing?.content?.slice(existing?.content?.lastIndexOf('/') + 1) || null)
      if (existing?.content.includes('rkings-security') && !item.loading) {
        this.$set(item, 'loading', true)
        const res = await AWSSecurityWithURL({ name: existing.content.slice(51) })
        this.$set(item, 'preview', (res?.blob ? URL.createObjectURL(res.blob) : null) || existing?.content || null)
        this.$set(item, 'loading', false)
      } else {
        this.$set(item, 'preview', existing?.content || null)
        this.$set(item, 'loading', false)
      }
    },

    setReceived() {
      try {
        this.isLoading = true
        let cnt = 0
        const profiles = []
        this.rwdTicked?.images.forEach(async (item) => {
          const existing = this.rwdTicked?.user_reward_profiles?.find?.(({ type }) => type == item.type)
          if (!item.uploaded && item.file) {
            await AwsSecurityUpdate('rkings-receipts', item.file, item.formatted_name)
              .then((res) => {
                this.$set(item, 'content', res.Location)
                this.$set(item, 'uploaded', true)
                profiles.push({ ...existing, type: item.type, content: res.Location, position: 0 })
              })
              .catch((err) => {
                throw err
              })
          } else if (existing) {
            profiles.push(existing)
          }
          cnt += 1
          if (this.images.length === cnt) {
            const params = { id: this.rwdTicked?.id, profiles: profiles }
            if (this.rwdTicked?.delivery_comment) {
              const existingComment = this.rwdTicked?.user_reward_profiles?.find?.(({ type }) => type == 'delivery_comment')
              params.profiles.push({ ...existingComment, type: 'delivery_comment', content: this.rwdTicked?.delivery_comment })
            }
            this.rwdTicked?.user_reward_profiles?.map((profile) => {
              if (params.profiles.findIndex((item) => profile.id == item.id) == -1) {
                params.profiles.push(profile)
              }
            })
            axios
              .post(API_LIST.post.updateWinnerDetail, params)
              .then((res) => {
                if (res?.status == 200) {
                  notifySuccess(res, `Set status to received successfully.`)
                  this.getRwdItems()
                }
              })
              .catch((err) => notifyError(err, `Failed to set status to received.`))
              .finally(() => (this.isLoading = false))
          }
        })
      } catch (error) {
        notifyError(error, this.$t('notify.unknown_err'))
        this.isLoading = false
      }
    },
  },

  created() {},
}
</script>

<style scoped>
::v-deep .modal-dialog {
  width: max-content;
  min-width: 50%;
  max-width: 75%;
}
</style>
