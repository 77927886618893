<template>
  <div class="rounded bg-white p-4" style="margin-left: -16px; margin-right: -16px">
    <div class="w-100 d-flex flex-row align-items-center">
      <label class="font-weight-normal m-0">Live Draw Prize Settings<span class="text-danger ml-1">*</span></label>
      <b-form-select
        v-model="rwdTypeInp"
        :options="[
          { value: 'product', text: 'Product (Lookup)' },
          { value: 'cash', text: $t('product.types.cash') },
          { value: 'cash_multiple', text: $t('product.types.cash_multiple') },
          { value: 'credit', text: $t('product.types.credit') },
        ]"
        @change="onChangeType"
        style="width: 200px"
        class="ml-2">
        <b-form-select-option selected disabled hidden :value="null">Select Prize Type</b-form-select-option>
      </b-form-select>

      <!-- <b-button variant="outline-primary" class="ml-3 my-0" @click="showLookup" v-if="!noEdit">
        {{ $t('product.product_lookup') }}
      </b-button>
      <b-button variant="outline-primary" class="ml-2 my-0" @click="addPrize" v-if="!noEdit">
        {{ $t('product.add_cash_prize') }}
      </b-button> -->
      <div class="ml-auto">
        <span class="font-weight-normal text-success">{{ $t('general.total') }}: {{ allCosts }}</span>
      </div>
    </div>

    <b-table
      show-empty
      small
      hover
      responsive
      sticky-header="360px"
      head-variant="light"
      class="mt-3 mb-0 mx-0 p-0"
      tbody-class="h-100"
      :empty-text="'⚠ ' + $t('product.at_least_one')"
      :fields="liveDrawFields"
      :items="form['liveDraw']">
      <template #head()="{ label, field }">{{ label }}<b-icon-question-circle-fill v-if="field.tooltip" class="ml-1" v-b-tooltip.hover.topright="field.tooltip" /></template>

      <template #cell(name)="{ value, item }">
        <!-- <b-form-select v-if="item.isCash" v-model="item['type']" :options="cashOpts" @change="onChangeType($event, item)" /> -->
        <span v-if="!item.id">{{ $t('product.types.' + item.type) }}</span>
        <div v-else-if="item.nameLang" class="w-auto d-flex flex-row flex-nowrap align-items-center">
          <span class="action" @click="viewProdDetail(item)" v-b-tooltip.hover.right="value">{{ value }}</span>
          <icon-translate :onClick="() => pushProductEdit(item)" classes="pl-1" />
        </div>
        <span v-else class="action" @click="viewProdDetail(item)" v-b-tooltip.hover.right="value">{{ value }}</span>
      </template>

      <template #cell(must_go)="{ item }">
        <b-form-checkbox v-model="item['must_go']" :value="1" :unchecked-value="0" switch />
      </template>

      <template #cell(store_id)="{ value }"><store-badge :store_id="value" /></template>

      <template v-for="name in ['cost', 'alternative_cash_amount', 'alternative_credit_amount']" v-slot:[`cell(${name})`]="{ index, value, item }">
        <b-input-group
          v-if="name == 'cost' ? !item.id : name == 'alternative_cash_amount' ? item.id || item.type == 'cash_multiple' : item.type != 'credit'"
          :id="name + '-' + index"
          :key="name + '-' + index"
          style="width: 148px">
          <b-form-input :value="item.store_id == 2 ? '$' : '£'" readonly class="px-2 flex-grow-0 flex-shrink-0" style="width: 32px" />
          <b-form-input v-model="item[name]" :required="name == 'cost'" number type="number" step="0.01" min="0.01" class="text-right" />
        </b-input-group>
        <template v-else>{{ formatWithCurrency(value, item.currency, '-') }}</template>
      </template>

      <template #cell(action)="{ item }">
        <slot name="action" :item="item"></slot>
      </template>

      <template #cell(payment_frequency)="{ value, item }">
        <b-form-select v-if="item.isCash && item.type == 'cash_multiple'" v-model="item['cash_period']" :options="frequencyOpts" class="text-capitalize" />
        <template v-else>{{ value }}</template>
      </template>

      <template #cell(payment_numbers)="{ value, item }">
        <b-form-input v-if="item.isCash && item.type == 'cash_multiple'" v-model="item['cash_times']" number type="number" step="1" min="1" class="text-center" />
        <template v-else>{{ value }}</template>
      </template>

      <template #bottom-row v-if="prodStoreValid">
        <td class="font-weight-bold text-left">{{ $t('order.subtotal') }}</td>
        <td colspan="2"></td>
        <td class="font-weight-bold text-right">{{ subtotal.allFormatted }}</td>
      </template>
    </b-table>
    <b-form-invalid-feedback :state="prodStoreValid">⚠ {{ $t('competition.same_store_notice') }}</b-form-invalid-feedback>
  </div>
</template>
<script>
import productMixin from '@/mixins/product-mixin';
import routerMixin from '@/mixins/router-mixin';
import { noTransFormatter } from '@/utils/index';
import { floatAddition, formatWithCurrency } from '@/utils/numberUtils';
// import { mapGetters } from 'vuex'

export default {
  name: 'LiveDrawSetting',
  mixins: [
    routerMixin,
    productMixin, // frequencyOpts, getExistingMultiPay
  ],
  props: {
    lookupType: String,
    form: Object,
    // noEditByStatus: Boolean,
    // prodAmtValid: Boolean || null,
    prodStoreValid: Boolean || null,
    viewProdDetail: Function,
    instantWinProdAmt: Function,
    subtotal: Object,
    instantWinTotal: Number,
  },
  data() {
    return {
      rwdTypeInp: null,
    }
  },
  computed: {
    // ...mapGetters({
    //   selfStore: 'store',
    //   allowMultiStores: 'allowMultiStores',
    // }),
    // noEditMultiStore() {
    //   return this.initVal?.stores?.length > 0 && !(this.allowMultiStores || this.form.liveDraw[0]?.store_id == this.selfStore)
    // },
    // noEdit() {
    //   return this.noEditByStatus || this.noEditMultiStore
    // },
    currency() {
      return this.form.liveDraw[0]?.currency
    },
    liveDrawFields() {
      const tmp = [
        { key: 'name', label: this.$t('competition.prize_name'), formatter: noTransFormatter, thStyle: 'width: 12rem', tdAttr: { style: 'white-space: normal; min-width: 200px' } },
        { key: 'must_go', tooltip: this.$t('competition.must_go_notice') },
        { key: 'store_id', label: this.$tc('general.store', 1), tooltip: this.$t('competition.same_store_notice') },
        { key: 'cost', label: this.$t('general.cost'), class: 'text-right' },
        { key: 'alternative_cash_amount', label: this.$t('competition.alt_cash'), tooltip: this.$t('competition.alt_cash_tip'), class: 'text-right' },
        { key: 'alternative_credit_amount', label: this.$t('competition.alt_credit'), tooltip: this.$t('competition.alt_credit_tip'), class: 'text-right' },
        { key: 'action', label: this.$tc('general.action', 1) },
        { key: 'payment_frequency', formatter: (value, key, item) => (item.isCash ? item.cash_period : this.getExistingMultiPay(item)?.code) ?? 'One-off', tdClass: 'text-capitalize' },
        {
          key: 'payment_numbers',
          label: this.$t('product.pymt_times'),
          formatter: (value, key, item) => (item.isCash ? item.cash_times : this.getExistingMultiPay(item)?.value) ?? 1,
          tdClass: 'text-capitalize',
          class: 'text-center',
        },
        {
          key: 'first_payment_date',
          formatter: (value, key, item) => {
            if (item.isCash) {
              return item.cash_period ? this.firstPayDate : '-'
            } else {
              const multiPay = this.getExistingMultiPay(item)
              return multiPay?.code && multiPay?.value ? this.firstPayDate : '-'
            }
          },
        },
        {
          key: 'last_payment_date',
          formatter: (value, key, item) => {
            let multiPay = null
            if (!item.isCash) {
              multiPay = this.getExistingMultiPay(item)
            } else if (item.cash_period) {
              multiPay = { code: item.cash_period, value: item.cash_times }
            }
            if (!multiPay?.code || !multiPay?.value) {
              return '-'
            } else if (this.form.draw_date) {
              return this.$moment(this.form.draw_date).add(Number(multiPay.value), multiPay.code.replaceAll('ly', '')).format('YYYY-MM-DD')
            } else {
              return this.$t('product.last_pymt_tip')
            }
          },
        },
      ]
      return tmp
    },
    cashOpts() {
      return (
        this.$store.getters['request/productEntityList']?.reduce(
          (arr, item) => (item?.includes('cash') ? arr.toSpliced(arr.length, 0, { value: item, text: this.$t(`product.types.${item.replace('-', '_')}`) }) : arr),
          []
        ) || []
      )
    },
    allCosts() {
      let num = 0
      let str = ''
      if (this.form.liveDraw.length > 0) {
        num = floatAddition(this.subtotal?.all, this.instantWinTotal)
        if (this.instantWinTotal > 0) {
          str = formatWithCurrency(this.subtotal?.all, this.currency) + ' + ' + formatWithCurrency(this.instantWinTotal, this.currency) + ' = ' + formatWithCurrency(num, this.currency)
        } else {
          str = formatWithCurrency(num, this.currency)
        }
      }
      this.$set(this.form, 'total_cost', num)
      return str
    },
    firstPayDate() {
      return this.form.draw_date ? this.$moment(this.form.draw_date).add(1, 'd').format('YYYY-MM-DD') : this.$t('product.first_pymt_tip')
    },
  },

  watch: {
    lookupType(newVal, oldVal) {
      if (newVal != oldVal && !newVal) {
        this.rwdTypeInp = null
      }
    },
  },

  methods: {
    showLookup(evt) {
      this.$emit('update:lookupType', 'liveDraw')
      this.$bvModal.show('product-lookup')
    },

    maxHoldAmt(item) {
      return item.available_amount - this.instantWinProdAmt(item)
    },

    // cash prize
    addPrize(type) {
      this.form.liveDraw.push({
        isCash: type.includes('cash') ? 'cash_prize_' + Date.now() : false,
        reward_type: 'winner',
        must_go: 1,
        store_id: 1,
        type,
        cash_period: type == 'cash_multiple' ? this.frequencyOpts[0] : null,
        cash_times: type.includes('cash') ? 1 : null,
        currency: 'GBP',
        cost: 0,
        amount: 1,
        stock_amount: 1,
        available_amount: 1,
      })
    },

    // onChangeType($event, item) {
    //   item['cash_period'] = $event == 'cash_multiple' ? this.frequencyOpts[0] : null
    //   item['cash_times'] = 1
    // },
    onChangeType($event) {
      if ($event == 'product') {
        this.showLookup($event)
      } else {
        this.addPrize($event)
      }
    },

    formatWithCurrency,
  },
}
</script>
<style lang="scss" scoped>
.product-details-modal {
  width: max-content !important;
  min-width: 56rem !important;
  max-width: 90vw !important;
}
@media screen and (max-width: 1024px) {
  .modal-dialog {
    max-width: 80vw;
    margin-left: 10vw;
  }
  .modal-content {
    max-height: 80vh;
  }
}
</style>
