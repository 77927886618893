<template>
  <div class="px-4 pt-3 pb-2 d-flex flex-row flex-wrap align-items-start">
    <b-form-select v-model="queryParams['store_id']" :disabled="!allowMultiStores" class="mb-2 mr-2" style="width: 90px">
      <b-form-select-option selected disabled hidden :value="null">{{ $tc('general.store', 1) }}</b-form-select-option>
      <b-form-select-option :value="''">{{ $t('general.all') + ' ' + $tc('general.store', 2) }}</b-form-select-option>
      <b-form-select-option :value="1">{{ $t('uk') }}</b-form-select-option>
      <b-form-select-option :value="2">{{ $t('address.mexico') }}</b-form-select-option>
    </b-form-select>
    <b-form-select v-model="queryParams['reward_type']" class="mb-2 mr-2" style="width: 160px">
      <b-form-select-option :value="null">All Winners</b-form-select-option>
      <b-form-select-option value="winner">Live Draw Winners</b-form-select-option>
      <b-form-select-option value="consolation">Instant Winner</b-form-select-option>
    </b-form-select>
    <b-form-select v-model="queryParams['tracking_status']" class="mb-2 mr-2" style="width: 170px">
      <b-form-select-option :value="null">{{ $t('general.all') + ' ' + $t('winner.tracking_status') }}</b-form-select-option>
      <b-form-select-option :value="0">{{ $t('winner.tracking_status_list')[0] }}</b-form-select-option>
      <b-form-select-option :value="1">{{ $t('winner.tracking_status_list')[1] }}</b-form-select-option>
    </b-form-select>
    <b-form-select v-model="queryParams['status']" class="mb-2 mr-2" style="width: 120px">
      <b-form-select-option :value="null">All Status</b-form-select-option>
      <b-form-select-option :value="-1">{{ $t('winner.status_list[0]') }}</b-form-select-option>
      <b-form-select-option :value="0">{{ $t('winner.status_list[1]') }}</b-form-select-option>
      <!-- <b-form-select-option :value="1">DELIVERING</b-form-select-option> -->
      <!-- <b-form-select-option :value="2">REDEEM</b-form-select-option> -->
      <b-form-select-option :value="3">{{ $t('winner.status_list[4]') }}</b-form-select-option>
    </b-form-select>
    <b-form-input
      v-model="queryParams['ticket_number']"
      type="search"
      placeholder="Instant Win Ticket Number"
      @keyup.enter="onSearch"
      class="mb-2 mr-2"
      style="width: 216px"
      v-b-tooltip.hover.top="`Only for instant winner`" />
    <b-form-input v-model="queryParams['user_name']" type="search" placeholder="Username" @keyup.enter="onSearch" class="mb-2 mr-2" style="width: 180px" />
    <b-form-input v-model="queryParams['title']" type="search" placeholder="Title" @keyup.enter="onSearch" class="mb-2 mr-2" style="width: 160px" />
    <b-form-input v-model="queryParams['winner_id']" type="search" placeholder="Winner ID" @keyup.enter="onSearch" class="mb-2 mr-2" style="width: 120px" />
    <b-form-input v-model="queryParams['competition_id']" type="search" :placeholder="$t('competition.comp_id')" @keyup.enter="onSearch" class="mb-2 mr-2" style="width: 140px" />
    <date-range-picker
      v-model="dateRange"
      :locale-data="{ format: 'yyyy-mm-dd' }"
      :auto-apply="true"
      :ranges="ranges"
      :timePicker24Hour="true"
      :show-dropdowns="true"
      class="mb-2 mr-2"
      style="width: max-content; min-width: 220px"
      :max-date="maxDate"
      v-b-tooltip.hover.top="$t('notify.date_range_notice', { name: 'Created At' })" />
    <button-group :onSearch="onSearch" :onReset="onReset" class="mb-2">
      <template #export>
        <b-button variant="reset" @click="onExport" class="ml-2">
          <b-icon-file-spreadsheet class="mr-1" />
          {{ isLoading ? $t('status.loading') : $t('action.export') }}
        </b-button>
        <b-button variant="reset" v-b-modal.confirm-export-invoices>
          <b-icon-file-zip class="mr-1" />
          {{ isLoading ? $t('status.loading') : $t('winner.export_receipt') }}
        </b-button>
        <confirm-modal id="confirm-export-invoices" :title="$t('product.export_invoices')" :message="$t('product.export_invoices_notice')" :isLoading="isLoading" :onSubmit="onExportInvoices" />
      </template>
    </button-group>
  </div>
</template>
<script>
import service from '@/store/services/service'
import { API_LIST } from '@/utils/consts'
import { formatDayEndUtc, formatDayStartUtc, formatLocalDateTime, formatUtcDateTime, setupDateRanges } from '@/utils/dateTimeUtils'
import { exportAsExcel, exportAsZipWithBlob } from '@/utils/fileUtils'
import { notifyError } from '@/utils/index'
import { formatWithCurrency } from '@/utils/numberUtils'
import { mapGetters } from 'vuex'
import { AWSSecurityWithURL } from '../../store/services/aws'

export default {
  name: 'WinnerSearchInput',
  data() {
    return {
      ranges: setupDateRanges(),
    }
  },
  props: {
    queryParams: Object,
    onSearch: Function,
    isLoading: Boolean,
  },
  computed: {
    ...mapGetters({ winnerList: 'request/winnerList', allowMultiStores: 'allowMultiStores', selfStore: 'store' }),
    dateRange: {
      get() {
        return {
          startDate: formatLocalDateTime(this.queryParams.start, 1, false, true),
          endDate: formatLocalDateTime(this.queryParams.end, 1, false, true),
        }
      },
      set(newVal) {
        this.queryParams['start'] = formatDayStartUtc(newVal.startDate, 0, false, true)
        this.queryParams['end'] = formatDayEndUtc(newVal.endDate, 0, false, true)
        return newVal
      },
    },
    maxDate() {
      return formatLocalDateTime(null, 3, true, true)
    },
  },
  methods: {
    onReset() {
      this.queryParams.store_id = this.allowMultiStores ? null : this.selfStore
      this.queryParams.ticket_number = null
      this.queryParams.winner_id = null
      this.queryParams.competition_id = null
      this.queryParams.title = null
      this.queryParams.user_name = null
      this.queryParams.status = null
      this.queryParams.tracking_status = null
      this.queryParams.reward_type = 'winner'
      this.queryParams.start = null
      this.queryParams.end = null
      this.dateRange['startDate'] = null
      this.dateRange['endDate'] = null
      this.onSearch()
    },
    preProcess(data) {
      for (let i in data) {
        data[i].store_id = [this.$t('uk'), this.$t('address.mexico')][data[i].competition.store_id - 1]
        data[i].order_id = `#${data[i].order_id}`
        data[i].winner_name = typeof data[i].profiles.find((winner) => winner.type === 'display_name') !== 'undefined' ? data[i].profiles.find((winner) => winner.type === 'display_name').content : ''
        data[i].title = data[i].profiles.length > 0 ? data[i].profiles.find((winner) => winner.type === 'title').content : ''
        data[i].won_for =
          typeof data[i].profiles.find((winner) => winner.type === 'price') !== 'undefined' ? formatWithCurrency(data[i].profiles.find((winner) => winner.type === 'price').content, 'GBP') : ''
        data[i].display_name = data[i].user.display_name
        data[i].status = this.$t('winner.status_list')[data[i].status + 1]
        data[i].created_at = formatLocalDateTime(data[i].created_at)
        let arr = ['meta_data', 'id', 'competition_id', 'user_id', 'product_id', 'user', 'profiles', 'updated_at', 'address', 'product', 'competition']
        arr.forEach((key) => {
          delete data[i][key]
        })
      }
    },
    onExport() {
      return exportAsExcel(
        this.$t('winner.list_filename') + '_' + formatUtcDateTime(undefined, 2, true),
        API_LIST.get.winnerList,
        { ...this.queryParams, page: 1, perPage: 1000 },
        this.winnerList.total,
        this.preProcess,
        ['title', 'store_id', 'ticket_number', 'order_id', 'winner_name', 'display_name', 'status', 'won_for', 'created_at', 'reward_id', 'tracking_status'],
        [
          [
            this.$t('general.title'),
            this.$tc('general.store', 1),
            'Ticket Number',
            this.$t('order.order_id'),
            this.$t('winner.winner_name'),
            this.$t('general.username'),
            this.$t('general.status'),
            this.$t('winner.won_for'),
            this.$t('general.created_at'),
            this.$t('winner.reward_id'),
            this.$t('winner.tracking_status'),
          ],
        ],
        (bool) => this.$emit('update:isLoading', bool)
      )
    },
    async onExportInvoices() {
      try {
        this.$emit('update:isLoading', true)
        const queryParams = { ...this.queryParams }
        queryParams['perPage'] = 1000
        queryParams['page'] = 1
        queryParams['reward_type'] = null
        queryParams['tracking_status'] = 1
        let blobs = []
        var total = 0
        var cnt = 0
        const pushBlobs = (res) => {
          queryParams.page++
          if (res.status === 200) {
            cnt += res.data.data.data.length
            res?.data?.data?.data?.forEach?.((winner) => {
              winner?.profiles?.forEach?.((attr) => {
                if (['poa', 'receipt', 'photoID'].includes(attr.type) && blobs.indexOf(attr.content) == -1) {
                  if (attr.content?.slice?.(attr.content?.length - 4) == '.pdf') {
                    blobs.splice(0, 0, attr.content)
                  } else if (attr.content?.includes?.('rkings-security')) {
                    blobs.push(AWSSecurityWithURL({ name: attr.content?.slice?.(51) }))
                  }
                }
              })
            })
          }
        }
        await service.generalFetcher(API_LIST.get.winnerList, queryParams).then((res) => {
          total = res.data.data.total // get total counts first
          pushBlobs(res)
        })
        if (total > 0) {
          while (cnt < total) {
            await service.generalFetcher(API_LIST.get.winnerList, queryParams).then(pushBlobs) // loop until all search results fetched
          }
          const zipFilename = this.$tc('general.winner', 1) + this.$tc('product.invoice', 2) + '_' + formatUtcDateTime(undefined, 'YYYY-MM-DD-HH-mm-ss', true) + '.zip'
          exportAsZipWithBlob(blobs, zipFilename, (bool) => this.$emit('update:isLoading', bool))
        } else {
          this.$emit('update:isLoading', false)
          this.$notify({ group: 'root', type: 'warn', text: this.$t('winner.export_receipts_no_res') })
          return
        }
      } catch (error) {
        this.$emit('update:isLoading', false)
        notifyError(error, this.$t('winner.export_receipts_failed'))
        throw error
      }
    },
  },
}
</script>
