<template>
  <b-table
    :caption-html="`<span class='text-dark'>${$tc('general.catalog', 2)}</span>`"
    caption-top
    show-empty
    :empty-text="$t('notify.table_no_records')"
    small
    striped
    hover
    responsive
    head-variant="light"
    class="m-0 p-0"
    :fields="['id', { key: 'name', label: $t('general.name') }, { key: 'position', label: $t('general.position') }, { key: 'is_active', label: $t('general.status'), sortable: true }]"
    :items="store.catalogOpts">
    <template #cell(name)="{ value, item }">
      <b-form-checkbox v-model="store['categories']" :value="item.id" :unchecked-value="null" :id="'catalog-checkbox-' + item.id" :name="'catalog-checkbox-' + item.id" class="text-capitalize">
        {{ value }}
      </b-form-checkbox>
    </template>
    <template #cell(position)="data">
      <b-form-input :disabled="true" v-model="store.catalogOpts[data.index].position" number type="number" min="0" step="1" :placeholder="$t('general.position')" style="width: 80px" />
    </template>
    <template #cell(is_active)="{ value }">
      <bool-badge :value="value" :text="value == 1 ? $t('status.shown') : $t('status.hidden')" />
    </template>
  </b-table>
</template>
<script>
export default {
  name: 'CatalogsEdit',
  props: {
    store: Object,
  },
}
</script>
