<template>
  <div :class="`d-flex flex-row flex-wrap align-items-start ${extraCls ? extraCls : ''}`">
    <div class="d-flex flex-row flex-wrap align-items-center">
      <template v-if="showStore">
        <store-multiselect v-if="isListRoute && allowMultiStores" :store_ids.sync="queries.store_ids" class="mb-2 mr-2" />
        <b-input-group v-else class="mr-2 mb-2" style="width: 90px">
          <b-form-select v-if="!isListRoute && allowMultiStores" v-model="queries.store_id" :options="storeOpts">
            <b-form-select-option :value="null">{{ $t('general.all') + ' ' + $tc('general.store', 2) }}</b-form-select-option>
          </b-form-select>
          <b-form-select v-else :value="selfStore" :options="storeOpts" disabled />
        </b-input-group>
      </template>
      <b-input-group v-if="showName" class="mr-2 mb-2" style="width: 150px">
        <b-form-input type="search" v-model="queries.name" :placeholder="$t('product.product_name')" @keyup.enter="onSearch" />
      </b-input-group>
      <b-input-group v-if="showType" class="mr-2 mb-2" style="width: 130px">
        <b-form-select v-model="queries.type" :options="typeOpts" class="text-capitalize">
          <b-form-select-option selected disabled hidden :value="null">{{ $t('product.product_type') }}</b-form-select-option>
          <b-form-select-option :value="''">{{ $t('general.all') }}</b-form-select-option>
          <!-- <b-form-select-option value="car">{{ $t('product.types.car') }}</b-form-select-option>
          <b-form-select-option value="bundle">{{ $t('product.types.bundle') }}</b-form-select-option>
          <b-form-select-option value="travel-package">{{ $t('product.types.travel_package') }}</b-form-select-option>
          <b-form-select-option value="ticket">{{ $t('product.types.ticket') }}</b-form-select-option>
          <b-form-select-option value="electronics">{{ $t('product.types.electronics') }}</b-form-select-option> -->
        </b-form-select>
      </b-input-group>
      <b-input-group v-if="showStatus" class="mr-2 mb-2" v-b-tooltip.hover.left="$t('product.status_notice')" style="width: 135px">
        <b-form-select v-model="queries.status" :disabled="!isListRoute" class="text-capitalize">
          <b-form-select-option selected disabled hidden :value="null">{{ $t('product.stock_status') }}</b-form-select-option>
          <b-form-select-option :value="''">{{ $t('general.all') }}</b-form-select-option>
          <b-form-select-option v-for="value in [0, 1]" :key="value" :value="value" class="text-capitalize">{{ $t('product.stock_status_list')[value] }}</b-form-select-option>
        </b-form-select>
      </b-input-group>
      <b-input-group v-if="showAvailable" class="mr-2 mb-2" v-b-tooltip.hover.left="$t('product.available_notice')" style="width: 112px">
        <b-form-select v-model="queries.available" :disabled="!isListRoute">
          <b-form-select-option selected disabled hidden :value="null">{{ $t('product.availability') }}</b-form-select-option>
          <b-form-select-option :value="''">{{ $t('general.all') }}</b-form-select-option>
          <b-form-select-option :value="0">{{ $t('status.unavailable') }}</b-form-select-option>
          <b-form-select-option :value="1">{{ $t('status.available') }}</b-form-select-option>
        </b-form-select>
      </b-input-group>
      <b-input-group v-if="showInvoice" class="mr-2 mb-2" style="width: 130px">
        <b-form-select v-model="queries.has_invoice" :disabled="!isListRoute">
          <b-form-select-option selected disabled hidden :value="null">{{ $t('product.invoice_status[0]') }}</b-form-select-option>
          <b-form-select-option :value="''">{{ $t('general.all') }}</b-form-select-option>
          <b-form-select-option v-for="value in [0, 1]" :key="value" :value="value">{{ $t('product.invoice_status')[value + 1] }}</b-form-select-option>
        </b-form-select>
      </b-input-group>
    </div>

    <div class="d-flex flex-row flex-nowrap align-items-center justify-content-xl-between flex-grow-1">
      <button-group :onSearch="onSearch" :onReset="onReset">
        <template v-if="isListRoute" #export>
          <b-button variant="reset" @click="onExport" class="ml-2"><b-icon-file-spreadsheet class="mr-1" />{{ isLoading ? $t('status.loading') : $t('action.export') }}</b-button>
          <b-button variant="reset" v-b-modal.confirm-export-invoices><b-icon-file-zip class="mr-1" />{{ isLoading ? $t('status.loading') : $t('product.export_invoices') }}</b-button>
          <confirm-modal id="confirm-export-invoices" :title="$t('product.export_invoices')" :message="$t('product.export_invoices_notice')" :isLoading="isLoading" :onSubmit="onExportInvoices" />
        </template>
      </button-group>
      <b-button v-if="isListRoute" variant="basic" class="ml-2" @click="$router.push({ name: 'Product Create' })"> <b-icon-plus class="mr-1" />{{ $t('product.create_new_product') }} </b-button>
    </div>
  </div>
</template>
<script>
import storeMixin from '@/mixins/store-mixin'
import service from '@/store/services/service'
import { API_LIST } from '@/utils/consts'
import { formatLocalDateTime, formatUtcDateTime } from '@/utils/dateTimeUtils'
import { exportAsExcel, exportAsZip } from '@/utils/fileUtils'
import { notifyError } from '@/utils/index'
import { formatWithCurrency } from '@/utils/numberUtils'
import { mapGetters } from 'vuex'

export default {
  name: 'ProductSearchInput',
  mixins: [storeMixin], // storeOpts, selfStore, allowMultiStores
  props: {
    queries: Object,
    getList: Function,
    productList: Object,
    isLoading: Boolean,
    extraCls: String,
  },
  data() {
    return {
      showStore: false,
      showName: false,
      showType: false,
      showStatus: false,
      showInvoice: false,
      showAvailable: false,
      excelHeading: [
        'id',
        'name',
        'topImage',
        'store_id',
        'status',
        'type',
        'initial_amount',
        'stock_amount',
        'available_amount',
        'has_invoice',
        'cost',
        'storing_time',
        'delivering_time',
        'created_at',
        'updated_at',
        'admin',
      ],
      excelHeadingFormat: [
        [
          this.$t('general.id'),
          this.$t('product.product_name'),
          this.$t('general.top_image'),
          this.$tc('general.store', 1),
          this.$t('product.stock_status'),
          this.$t('general.type'),
          this.$t('product.initial_amount'),
          this.$t('product.stock_amount'),
          this.$t('product.available_amount'),
          this.$t('product.invoice_status[2]'),
          this.$t('general.cost'),
          this.$t('product.storing_time'),
          this.$t('product.delivering_time'),
          this.$t('general.created_at'),
          this.$t('general.updated_at'),
          this.$t('general.updated_by'),
        ],
      ],
    }
  },
  computed: {
    ...mapGetters({ productEntityList: 'request/productEntityList' }),
    isListRoute() {
      return this.$route.path.includes('/competitions/product-management')
    },
    typeOpts() {
      return this.productEntityList?.map((x) => ({ value: x, text: this.$t(`product.types.${x.replace('-', '_')}`) })) ?? []
    },
  },
  methods: {
    setIsLoading(bool) {
      this.$emit('update:isLoading', bool)
    },
    onSearch() {
      this.queries.page = 1
      this.getList()
    },
    onReset() {
      if (this.showStore) {
        this.queries.store_ids = this.allowMultiStores ? null : `[${this.selfStore}]`
        this.queries.store_id = this.allowMultiStores ? null : this.selfStore
      }
      if (this.showName) {
        this.queries.name = null
      }
      if (this.showType) {
        this.queries.type = null
      }
      if (this.showStatus) {
        this.queries.status = this.isListRoute ? null : 1
      }
      if (this.showInvoice) {
        this.queries.has_invoice = null
      }
      if (this.showAvailable) {
        this.queries.available = this.isListRoute ? null : 1
      }
      this.onSearch()
    },
    csvPreProcess(data) {
      for (let i in data) {
        data[i].store_id = [this.$t('uk'), this.$t('address.mexico')][data[i].store_id - 1]
        data[i].admin = data[i].admin ? data[i].admin.name : this.$t('no_data')
        data[i].delivering_time = data[i].delivering_time ?? this.$t('no_data')
        data[i].storing_time = formatLocalDateTime(data[i].storing_time)
        data[i].topImage = data[i].topImage ?? this.$t('notify.no_image')
        data[i].type = data[i].type ? this.$t(`product.types.${data[i].type.replace('-', '_')}`) : this.$t('no_data')
        data[i].status = data[i].status ? this.$t('product.stock_status_list')[data[i].status] : this.$t('no_data')
        data[i].cost = data[i].cost ? formatWithCurrency(data[i].cost, data[i].currency) : this.$t('no_data')
        data[i].created_at = formatLocalDateTime(data[i].created_at)
        data[i].updated_at = formatLocalDateTime(data[i].updated_at)
        let arr = ['description', 'currency', 'admin_user_id', 'attributes']
        arr.forEach((key) => {
          delete data[i][key]
        })
      }
    },
    onExport() {
      return exportAsExcel(
        this.$t('product.list_filename') + `_${formatUtcDateTime(undefined, 2, true)}`,
        API_LIST.get.productList,
        { ...this.queries, page: 1, perPage: 1000 },
        this.productList.total,
        this.csvPreProcess,
        this.excelHeading,
        this.excelHeadingFormat,
        this.setIsLoading
      )
    },
    async onExportInvoices() {
      if (this.queries.has_invoice === 0) {
        this.$notify({ group: 'root', type: 'warn', text: this.$t('product.export_invoices_no_res') })
        return
      } else {
        try {
          this.setIsLoading(true) // get all the image urls
          const queryParams = { ...this.queries }
          queryParams['perPage'] = 1000
          queryParams['page'] = 1
          queryParams['has_invoice'] = 1
          let urls = []
          var total = 0
          var cnt = 0
          const pushUrls = (res) => {
            queryParams.page++
            if (res?.status === 200) {
              cnt += res?.data?.data?.data?.length
              res?.data?.data?.data?.forEach?.((product) => {
                product?.attributes?.forEach?.((attr) => {
                  if (attr.code == 'invoice' && urls.indexOf(attr.value) == -1) {
                    if (attr.value.slice(attr.value.length - 4) == '.pdf') {
                      urls.splice(0, 0, attr.value)
                    } else {
                      urls.push(attr.value)
                    }
                  }
                })
              })
            }
          }
          await service.generalFetcher(API_LIST.get.productList, queryParams).then((res) => {
            total = res?.data?.data?.total // get total counts first
            pushUrls(res)
          })
          if (total > 0) {
            while (cnt < total) {
              await service.generalFetcher(API_LIST.get.productList, queryParams).then(pushUrls) // loop until all search results fetched
            }
            const zipFilename = this.$tc('general.product', 1) + this.$tc('product.invoice', 2) + '_' + formatUtcDateTime(undefined, 'YYYY-MM-DD-HH-mm-ss', true) + '.zip'
            exportAsZip(urls, zipFilename, this.setIsLoading)
          } else {
            this.setIsLoading(false)
            this.$notify({ group: 'root', type: 'warn', text: this.$t('product.export_invoices_no_res') })
            return
          }
        } catch (error) {
          this.setIsLoading(false)
          notifyError(error, this.$t('product.export_invoices_failed'))
          throw error
        }
      }
    },
  },
  created() {
    this.showStore = this.queries.store_ids !== undefined
    this.showName = this.queries.name !== undefined
    this.showType = this.queries.type !== undefined
    this.showStatus = this.queries.status !== undefined
    this.showInvoice = this.queries.has_invoice !== undefined
    this.showAvailable = this.queries.available !== undefined
    // get product types
    if (!this.productEntityList) {
      this.setIsLoading(true)
      this.$store
        .dispatch('request/getProductEntityList')
        .then(() => this.setIsLoading(false))
        .catch(() => this.setIsLoading(false))
    }
  },
}
</script>
