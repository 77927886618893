<template>
  <b-modal
    lazy
    centered
    id="set-popup"
    :title="$t('competition.set_as_popup')"
    title-class="w-100 d-flex justify-content-center align-self-center"
    header-class="py-2"
    footer-class="py-2"
    body-class="py-0"
    @shown="onShown"
    @hidden="onHidden"
    ><b-form @submit.prevent="onSubmit" id="set-popup-form">
      <p class="m-0 text-sm text-slack">⚠ {{ $t('competition.popup_notice[0]') }}</p>
      <b-form-group label-for="popup-input-1" label-class="text-sm" class="mt-3 mb-0">
        <template #label>{{ $t('competition.comp_title') }}<span class="ml-1 text-danger">*</span></template>
        <b-form-input id="popup-input-1" v-model="title" required :placeholder="$t('competition.popup_title_notice[0]')" />
      </b-form-group>
      <b-form-group label-for="popup-input-2" label-class="text-sm" class="mt-3 mb-0">
        <template #label>{{ $t('general.content') }}<span class="ml-1 text-danger">*</span></template>
        <b-form-textarea id="popup-input-2" v-model="content" required rows="3" size="lg" no-resize :placeholder="$t('competition.popup_content_notice[0]')" />
      </b-form-group>

      <b-form-group label-for="popup-image" label-class="text-sm" class="mt-3 mb-0">
        <template #label>
          {{ $tc('general.image', 1) }} {{ $t('input.optional') }}
          <b-icon-question-circle-fill class="ml-1" v-b-tooltip.hover.right="$t('image.url_notice')" />
        </template>
        <b-input-group>
          <b-form-file accept="image/*" id="popup-image" v-model="file" :browse-text="$t('input.choose_file')" size="sm" class="h-100 text-truncate" />
          <b-input-group-append>
            <b-button @click="onReset" variant="outline-danger" class="h-100"><b-icon-x class="mr-1" />{{ $t('action.reset') }}</b-button>
          </b-input-group-append>
        </b-input-group>
        <p class="mt-1 mb-0 text-sm">⚠ {{ $t('image.size_notice') }}</p>
        <p class="mt-1 mb-0 text-sm">⚠ {{ $t('image.ratio_notice') }}</p>
        <p class="mt-1 text-sm">⚠ {{ $t('image.replace_notice') }}</p>
        <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="false" />
        <b-table show-empty small hover responsive head-variant="light" class="m-0 p-0" :fields="fields" :items="imageArr" :busy="isLoading">
          <template #cell(url)="{ value }">
            <span v-b-tooltip.hover.right="value">{{ value }}</span>
          </template>
          <template #cell(preview)="{ item }">
            <b-img-lazy :src="item.url" fluid block rounded style="max-height: 3rem" />
          </template>
          <template #cell(action)="{ item }">
            <b-button variant="outline-danger" size="xs" @click="clearFile(item)"><b-icon-trash /></b-button>
          </template>
        </b-table>
      </b-form-group>
    </b-form>
    <template #modal-footer="{ hide }">
      <b-button variant="outline-secondary" @click="hide">{{ $t('action.cancel') }}</b-button>
      <b-button variant="outline-primary" type="submit" form="set-popup-form">{{ $t('action.submit') }}</b-button>
    </template>
  </b-modal>
</template>
<script>
import { AwsUpdate } from '@/store/services/aws'
import service from '@/store/services/service'
import { base64Encode } from '@/utils/fileUtils'
import { notifyError } from '@/utils/index'
import { formatBytes, formatWithCurrency } from '@/utils/numberUtils'
export default {
  name: 'CompSetPopup',
  props: { comp: Object },
  data() {
    return {
      isLoading: false,
      title: '',
      content: '',
      image: null,
      imageArr: [],
      file: null,
      popupInfo: null,
    }
  },
  computed: {
    fields() {
      return [
        { key: 'url', label: this.$t('url'), tdClass: 'text-truncate' },
        { key: 'preview', label: this.$t('general.preview') },
        this.file ? { key: 'size', label: this.$t('general.size') + '(<600kb)', formatter: (v) => formatBytes(v), tdClass: (v) => (v >= 614400 ? 'text-danger' : '') } : null,
        { key: 'action', label: this.$tc('general.action', 1) },
      ]
    },
  },
  methods: {
    onReset(evt) {
      this.file = null
      this.title = this.popupInfo?.pop_title ?? (this.comp ? `Want to win ${this.comp.title}?` : '')
      this.content = this.popupInfo?.pop_content ?? (this.comp?.prices[0] ? `Only ${formatWithCurrency(this.comp.prices[0].amount, this.comp.prices[0].currency_code)} a ticket` : '')
      this.image = this.popupInfo?.pop_image ?? this.comp?.image ?? null
      const tmp = []
      if (this.image) {
        tmp.push({ url: this.image, size: '<600KB', uploaded: true })
      }
      this.imageArr = tmp
    },
    onShown(evt) {
      this.isLoading = true
      service.getPopupInfo({ competition_id: this.comp?.id }).then((res) => {
        this.popupInfo = res?.data?.data
        this.onReset()
        this.isLoading = false
      })
    },
    clearFile(item) {
      if (item.uploaded) {
        this.image = null
        this.imageArr = []
      } else {
        this.onReset()
      }
    },
    setPopup() {
      this.$store
        .dispatch('request/setPopUp', {
          competition_id: this.comp.id,
          title: this.title,
          content: this.content,
          store_id: this.comp.store_id,
          image: this.image,
          successMsg: this.$t('competition.popup_notice[1]'),
          errorMsg: this.$t('competition.popup_notice[2]'),
        })
        .then((res) => {
          this.isLoading = false
          if (res.status === 200) this.$bvModal.hide('set-popup')
        })
        .catch(() => (this.isLoading = false))
    },
    onSubmit(evt) {
      try {
        this.isLoading = true
        const index = this.imageArr.length - 1
        // has a file to upload
        if (this.file && !this.imageArr[index]?.uploaded) {
          AwsUpdate('rkings', this.file).then((res) => {
            // upload file success
            if (res && res.Location) {
              this.image = res.Location
              this.setPopup()
              if (this.imageArr[index]) {
                this.imageArr[index]['url'] = res.Location
                this.imageArr[index]['uploaded'] = true
              }
            } else {
              // upload file failed
              this.isLoading = false
              this.$notify({ group: 'root', type: 'error', title: 'Error', text: this.$t('image.upload_notice[1]') })
            }
          })
        } else {
          // has no file to upload
          this.setPopup()
        }
      } catch (error) {
        this.isLoading = false
        notifyError(error, this.$t('notify.unknown_err'))
        return
      }
    },
    onHidden(evt) {
      this.$emit('update:comp', null)
      this.title = ''
      this.content = ''
      this.image = null
      this.imageArr = []
      this.file = null
      this.popupInfo = null
    },
  },
  watch: {
    file(newVal, oldVal) {
      if (newVal) {
        base64Encode(newVal).then((value) => {
          const tmp = []
          tmp.push({ size: newVal.size, url: value, uploaded: false })
          this.imageArr = tmp
        })
      } else {
        this.onReset()
      }
    },
  },
}
</script>
